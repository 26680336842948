import React, { useRef } from "react";
import Input from "../Input";

export const TimeInput = (props) => {
  const inputRef = useRef(null);

  const wrapperStyles = {
    position: "relative",
    width: "100%",
  };

  const overlayStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: "pointer",
  };

  const handleClick = () => {
    inputRef.current.showPicker();
  };

  return (
    <div style={wrapperStyles} onClick={handleClick}>
      <Input
        {...props}
        type="time"
        id={props.id}
        value={props.value}
        placeholder={props.placeholder}
        className="time-input cursor-pointer"
        ref={inputRef}
      />
      <div style={overlayStyles}></div>
      <style jsx global>{`
        .time-input::-webkit-calendar-picker-indicator {
          background: none;
          color: var(--tw-color-primary-500);
        }

        .time-input::-webkit-datetime-edit-fields-wrapper,
        .time-input::-webkit-datetime-edit-text,
        .time-input::-webkit-datetime-edit-hour-field,
        .time-input::-webkit-datetime-edit-minute-field,
        .time-input::-webkit-datetime-edit-ampm-field {
          color: var(--tw-color-primary-500);
        }

        .time-input:focus {
          border-color: var(--tw-color-primary-500);
          box-shadow: 0 0 0 1px var(--tw-color-primary-500);
        }

        /* Time picker popup styles */
        .time-input::-webkit-time-picker-popup {
          background-color: white;
        }

        .time-input::-webkit-time-picker-popup .clock-face,
        .time-input::-webkit-time-picker-popup .clock-hand {
          background-color: var(--tw-color-primary-500);
        }

        .time-input::-webkit-time-picker-popup .clock-number {
          color: var(--tw-color-primary-500);
        }
      `}</style>
    </div>
  );
};

export default TimeInput;
