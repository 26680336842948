import React, { useState } from "react";
import { DatePicker } from "../../../../shared";
import { MaintenanceTable } from "./Table";

const EquipmentMaintenance = ({
  equipment,
  maintenanceHistory,
  tableConfig,
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (value) => {
    setSelectedDate(value);
  };

  return (
    <div>
      <div className="flex flex-row items-center justify-between bg-white pb-2">
        <div>
          <h2 className="text-xl font-semibold">Equipment Maintenance</h2>
          {/* <div>
            <DatePicker
              value={selectedDate}
              onChange={handleDateChange}
              placeholder="Select a date"
              format="MM/dd/yyyy"
            />
          </div> */}
        </div>
      </div>
      <MaintenanceTable
        equipment={equipment}
        maintenanceHistory={maintenanceHistory}
        tableConfig={tableConfig}
      />
    </div>
  );
};

export default EquipmentMaintenance;
