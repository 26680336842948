import { Link } from "react-router-dom";
import { renderCustomColumnDefs } from "../../../../../shared/Table/table.utils";
import {
  formatDate,
  renderAmount,
  renderTotalMileage,
} from "../../../../../utils";

// Config for table and form
const tableConfig = [
  // {
  //   Header: "#",
  //   accessor: "",
  //   Cell: (row) => {
  //     return <div>{row.row.id * 1 + 1}</div>;
  //   },
  //   disableSortBy: true,
  //   disableFilters: true,
  // },
  {
    id: "loadNumber",
    visible: true,
    required: true,
    minSize: 180,
    // editable: true,
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/jobs/${original._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  {
    id: "transactionDate",
    header: "Transaction Date",
    minSize: 170,
    cell: ({ getValue }) => {
      return formatDate(getValue(), null, true);
    },
  },

  {
    id: "driver",
    visible: false,
    cell: ({ getValue }) => {
      return getValue().firstName;
    },
    minSize: 50,
  },
  {
    header: "weight",
    id: "totalWeight",
    cell: ({ getValue }) => {
      return `${getValue()?.toLocaleString("en-US")} lb`;
    },
  },
  {
    header: "mileage",
    id: "totalMileage",
    cell: ({ getValue }) => {
      return renderTotalMileage(getValue()) + " mi";
    },
  },
  {
    header: "Fuel Charges",
    id: "totalFuelCharges",
    minSize: 180,
    cell: ({ getValue }) => {
      return renderAmount(getValue());
    },
  },
  {
    header: "Base Charges",
    id: "totalBaseCharges",
    minSize: 100,
    cell: ({ getValue }) => {
      return renderAmount(getValue());
    },
  },
  {
    header: "drop charges",
    id: "totalDropCharges",
    minSize: 100,
    cell: ({ getValue }) => {
      return renderAmount(getValue());
    },
  },
  {
    header: "Standby Time",
    id: "totalStandByTime",
    minSize: 100,
    cell: ({ getValue }) => {
      return `${getValue()} hrs`;
    },
  },
  {
    header: "other charges",
    id: "totalOtherCharges",
    minSize: 150,
    cell: ({ getValue }) => {
      return renderAmount(getValue());
    },
  },
  {
    id: "totalCharges",
    minSize: 120,
    cell: ({ getValue }) => {
      return renderAmount(getValue());
    },
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
