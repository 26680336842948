import {
  APP_TYPE,
  PARENT_COMPANY,
  REFRESH_TOKEN_NAME,
  TOKEN_NAME,
  USER,
} from "../../../config";
import { getSearchParams } from "../../../utils/request";
import { api } from "../../api";

const accountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query({
      query: () => "account/current",
      providesTags: ["CurrentUser"],
      transformResponse: (response) => {
        return response.data;
      },
    }),
    updateAccount: builder.mutation({
      query(data) {
        return {
          url: "account/update",
          method: "PUT",
          body: { user: data },
        };
      },
      invalidatesTags: ["CurrentUser"],
    }),
    signin: builder.mutation({
      query: (credentials) => ({
        url: "/account/login",
        method: "POST",
        body: credentials,
      }),
    }),
    currentUser: builder.query({
      query: () => ({
        url: "/account/current",
        method: "GET",
      }),
    }),
    refresh: builder.mutation({
      query: ({ refreshToken }) => ({
        url: "/account/refresh",
        method: "POST",
        body: { refreshToken },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/account/logout",
        method: "POST",
        credentials: "include",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          document.cookie = `${TOKEN_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          document.cookie = `${REFRESH_TOKEN_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          localStorage.removeItem(USER);
          localStorage.removeItem(PARENT_COMPANY);
          localStorage.removeItem(APP_TYPE);
        } catch (error) {
          console.error("Logout error:", error);
        }
      },
    }),
    forgotPassword: builder.mutation({
      query(data) {
        return {
          url: "account/forgot/password",
          method: "POST",
          body: data,
        };
      },
    }),
    changePassword: builder.mutation({
      query({ data, ...params }) {
        const query = getSearchParams(params);
        return {
          url: `account/change/password?${query}`,
          method: "POST",
          body: data,
        };
      },
    }),
    activateAccount: builder.mutation({
      query({ data, ...params }) {
        const query = getSearchParams(params);
        return {
          url: `account/activate?${query}`,
          method: "PUT",
          body: { user: data },
        };
      },
      invalidatesTags: ["CurrentUser"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCurrentUserQuery,
  useUpdateAccountMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useActivateAccountMutation,
  useSigninMutation,
  useCurrentUserQuery,
  useRefreshMutation,
  useLogoutMutation,
} = accountApi;
