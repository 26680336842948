import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../../shared/Card";
import { useAuth } from "../../../hooks/useAuth";
import ClientCard from "./ClientCard";
import ClientLocations from "./ClientLocations";
import ClientJobsTable from "./ClientJobsTable";
import { clientJobsStage } from "../../../constants/aggregate";

import {
  fetchClient,
  selectClient,
  updateClient,
} from "../../../redux/reducers/clients/clientSlice";
import ClientJobs from "./ClientJobs";
import ClientPayout from "./ClientPayout";
import {
  fetchLocations,
  selectAllLocations,
  resetLocations,
} from "../../../redux/reducers/locations/locationsSlice";
import { resetClient } from "../../../redux/reducers/clients/clientSlice";
import {
  fetchJobs,
  resetJobs,
  selectAllJobs,
} from "../../../redux/reducers/jobs/jobsSlice";
import { renderAmount } from "../../../utils";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Tabs from "../../../shared/Tabs";
import ClientSettings from "./Settings";
import ClientLocationsIndex from "../../Locations";
import ClientInvoicesTable from "./ClientInvoicesTable";
import ClientEquipmentIndex from "./ClientEquipmentIndex";
import request from "../../../utils/request";

import {
  selectAllInvoices,
  fetchInvoices,
  resetInvoices,
} from "../../../redux/reducers/invoices/invoicesSlice";
import ClientJobsTab from "./ClientJobsTab";
import { SidebarContainer } from "../../../components";
import { useCrumbs } from "../../../hooks/useCrumbs";
import ClientJobChart from "./ClientJobChart";
import { EditableText } from "../../../shared";
import { useGetEquipmentQuery } from "../../../redux/services/equipment";

const ClientShow = (props) => {
  const { user } = useAuth();
  const crumbs = useCrumbs();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientStatus = useSelector((state) => state.client.status);
  const client = useSelector(selectClient);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [sidebarContent, setSidebarContent] = useState(false);
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    if (clientStatus === "idle") {
      dispatch(
        fetchClient({ clientId: params.id, parentId: user.parentCompany })
      );
    }
  }, [clientStatus, user, params.id]);

  const locationsStatus = useSelector((state) => state.locations.status);
  const locations = useSelector(selectAllLocations);

  useEffect(() => {
    if (client && clientStatus === "idle") {
      crumbs.setLoading(true);

      dispatch(
        fetchLocations({ clientId: params.id, parentId: user.parentCompany })
      );
    }
  }, [clientStatus, user, params.id]);

  useEffect(() => {
    if (client._id) {
      crumbs.updateCrumbName({
        name: client.name,
        path: "/clients/" + client._id,
      });
      crumbs.setLoading(false);
    }
  }, [client._id]);

  const jobs = useSelector(selectAllJobs);
  const jobStatus = useSelector((state) => state.jobs.status);

  const invoices = useSelector(selectAllInvoices);
  const invoicesStatus = useSelector((state) => state.invoices.status);

  function renderTotalGrosss() {
    let totalData = jobs
      .map((item, i) => {
        return item.totalCharges;
      })
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
    return renderAmount(totalData);
  }

  const totalGross = renderTotalGrosss();
  useEffect(() => {
    if (client._id && jobStatus === "idle") {
      dispatch(
        fetchJobs({
          drivers: true,
          parent: user.parentCompany,
          clientId: client._id,
        })
      );
    }
  }, [jobStatus, dispatch, user, client]);

  useEffect(() => {
    if (client._id && invoicesStatus === "idle") {
      dispatch(
        fetchInvoices({
          jobs: true,
          parent: user.parentCompany,
          clientId: client._id,
        })
      );
    }
  }, [invoicesStatus, dispatch, user, client]);

  useEffect(() => {
    return () => {
      dispatch(resetLocations());
      dispatch(resetClient());
      dispatch(resetJobs());
      dispatch(resetInvoices());
    };
  }, []);

  useEffect(() => {
    if (client._id) {
      fetchReport();
    }
  }, [user, client]);

  function onSave(data) {
    dispatch(
      updateClient({ data, parentId: user.parentCompany, clientId: client._id })
    );
  }

  const { data: equipmentData, isLoading: isEquipmentLoading } =
    useGetEquipmentQuery(
      {
        page: 1,
        limit: 100,
        client: client._id,
      },
      {
        skip: !client._id, // Skip query until we have a client ID
      }
    );

  const fetchReport = async (view) => {
    const stages = clientJobsStage(
      "transactionDate",
      user.parentCompany,
      client._id
    );

    const response = await request(`/reports`, {
      method: "post",
      data: {
        stages,
        modelName: "Job",
        config: {
          0: {
            type: "$match",
            // useDate: "transactionDate",
            _id: "client",
          },
        },
      },
    });
    return Promise.all([response]).then((values) => {
      const finalData = values[0]?.data;

      setReportData(finalData);
    });
  };

  function handleSidebarContent(e) {
    const content = (
      <div className="bg-white h-full">
        <div className="bg-gray-100 p-3 flex justify-between items-center">
          <div className="font-bold">{e.name}</div>
          <div>
            <XMarkIcon
              onClick={() => {
                setSidebarContent();
                setSidebarVisible(false);
              }}
              className="w-4 h-4 cursor-pointer"
            />
          </div>
        </div>
      </div>
    );
    setSidebarContent(content);
  }

  const tabs = [
    {
      id: "Info",
      content: (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-rows-2 gap-4 p-3">
          <Card className="row-span-2 col-span-1">
            <ClientCard client={client} />
          </Card>
          <Card className="row-span-2 col-span-1">
            <div>All Jobs</div>
            <ClientJobChart data={reportData} />
          </Card>
          <Card className="col-start-3 col-span-1">
            <ClientJobs totalJobs={jobs.length} />
          </Card>
          <Card className="col-start-3 col-span-1 row-start-2">
            <ClientPayout payout={totalGross || 0} />
          </Card>
        </div>
      ),
    },
    {
      id: "Jobs",
      content: <ClientJobsTab jobs={jobs} client={client} />,
    },
    {
      id: "Equipment",
      content: (
        <ClientEquipmentIndex
          equipment={equipmentData?.equipment}
          client={client}
          loading={isEquipmentLoading}
        />
      ),
    },
    {
      id: "Locations",
      content: <ClientLocationsIndex />,
    },
    {
      id: "Invoices",
      content: <ClientInvoicesTable invoices={invoices} client={client} />,
    },
    {
      id: "Settings",
      content: <ClientSettings client={client} />,
    },
  ];

  function handleTabClick(_e, _item, path) {
    navigate(path);
  }

  function getDefaultTab() {
    if (location.hash) {
      const tabHash = location.hash.slice(1);
      return tabs.findIndex((tab) => tab.id === tabHash);
    }
    return;
  }

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderHeader = (headerProps) => {
    return (
      <div ref={headerRef}>
        <header className="flex items-center justify-between px-3 py-2 text-semibold text-gray-100">
          <div>{headerProps.title}</div>
          <div className="flex items-center space-x-3">
            <div className="md:flex items-center space-x-3 text-gray-800 hidden">
              {headerProps.actions}
            </div>
          </div>
        </header>
        <div className="flex justify-between md:hidden space-x-5 px-3 py-3 bg-white border-b">
          {headerProps.actions}
        </div>
      </div>
    );
  };

  // const actions = (
  //   <>
  //     <span className="mr-5">Created: {formatDate(client.dateCreated)}</span>
  //     <span>Updated: {formatDate(client.dateUpdated)}</span>
  //   </>
  // );

  const title = (
    <div className="text-gray-800 font-semibold text-lg flex items-center">
      <EditableText
        className="ml-1"
        iconClasses="h-3 w-3"
        id="name"
        value={client.name}
        size="sm"
        displayText={(v) => (
          <span className="text-gray-800 font-semibold text-lg">{v}</span>
        )}
        // loading={modalLoad}
        onSave={(e) => onSave({ ...e.data, name: e.value })}
      />
    </div>
  );

  return (
    <SidebarContainer
      title={title}
      header={renderHeader}
      // actions={actions}
      // sidebarContent={sidebarContent}
      hideSidebar
      // hideHeader
      // sidebarTitle={<h2 className="text-xl bg-white py-2">Communications</h2>}
    >
      {({ parentHeight, parentWidth }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
        const panelStyle = {
          height: `${parentHeight - offsetTop - offsetHeight - 38}px`,
        };
        return (
          <Tabs
            className={`w-full overflow-hidden`}
            style={wrapperStyle}
            panelStyle={panelStyle}
            useSearchParams
            panelClasses={`border-t overflow-y-scroll`}
            tabListClasses="bg-white px-2 h-[38px]"
            tabclassName="mx-1"
            id="invoice-show"
            onClick={handleTabClick}
            data={tabs}
          />
        );
      }}
      {/* </SidebarContainer>
        return (
          <div style={wrapperStyle} className="px-3 pb-3 h-auto">
            {clientStatus === "loading" ? (
              <Spinner />
            ) : (
              <div className="grid grid-cols-1 gap-4">
                <Tabs
                  default={getDefaultTab()}
                  onClick={handleTabClick}
                  data={tabs}
                />
              </div>
            )}
          </div>
        );
      }} */}
    </SidebarContainer>
  );
};

export default ClientShow;
