import React, { useEffect, useState } from "react";
import Modal from "../../../shared/Modal";
import Button from "../../../shared/Button";
import useForm from "../../../hooks/useForm";
import { useAuth } from "../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
// import {
//   fetchOrganizations,
//   selectOrganizationsOptions,
// } from "../../../redux/reducers/organizations/organizationsSlice";
import { FORM_OPTIONS } from "../employee.table.config";
import EmployeeCreateForm from "./EmployeeCreateForm";
import { useCreateEmployeeMutation } from "../../../redux/services/employees/employeesApi";

const EmployeeCreateModal = ({
  visible,
  setVisible,
  hideOrg,
  children,
  onEmployeeCreated,
  ...props
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { form, clearForm, updateForm, errors } = useForm(FORM_OPTIONS);
  const [employeeOrg, setEmployeeOrg] = useState(false);

  //   const orgs = useSelector((state) => state.organizations);
  //   const orgOptions = useSelector(selectOrganizationsOptions);

  const [createEmployee] = useCreateEmployeeMutation();

  //   useEffect(() => {
  //     if (employeeOrg && orgs.status === "idle") {
  //       dispatch(fetchOrganizations({ parent: user.parentCompany }));
  //     }
  //   }, [employeeOrg, orgs.status]);

  function handleClose() {
    setVisible(false);
    setLoading(false);
    clearForm();
    setEmployeeOrg(false);
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      const result = await createEmployee({
        ...form,
        type: "employee",
        meta: {
          department: form.department,
          role: form.role,
        },
        parent: user.parentCompany,
      }).unwrap();

      toast.success("Successfully created new employee");
      handleClose();
      onEmployeeCreated?.(result);
    } catch (error) {
      toast.error("Failed to create employee");
      setLoading(false);
    }
  }

  return (
    <Modal
      title="Create Employee"
      visible={visible}
      onClose={handleClose}
      footer={
        <div className="flex justify-end space-x-2">
          <Button onClick={handleClose} variant="outline">
            Cancel
          </Button>
          <Button onClick={handleSubmit} loading={loading}>
            Create
          </Button>
        </div>
      }
    >
      <EmployeeCreateForm
        form={form}
        updateForm={updateForm}
        errors={errors}
        employeeOrg={employeeOrg}
        setEmployeeOrg={setEmployeeOrg}
        clearForm={clearForm}
        hideOrg={hideOrg}
        // orgOptions={orgOptions}
        user={user}
      />
    </Modal>
  );
};

export default EmployeeCreateModal;
