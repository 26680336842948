import { useState, useCallback, lazy, Suspense } from "react";
import { useForm, useConfig } from "../../../../hooks/";
import Spinner from "../../../../shared/Spinner";
import { LEG_FORM_CONFIG } from "../../../../constants/validations/leg";

const TruckingJobInfo = lazy(() => import("./Trucking"));
const ServiceRepairJobInfo = lazy(() => import("./ServiceRepair"));

const JobInfo = ({ job = {}, ...props }) => {
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const { configs, appType } = useConfig();
  const form = useForm(appType === "trucking" ? LEG_FORM_CONFIG : {});

  const showCreateModal = useCallback((vis) => {
    setCreateModalVisible(vis === false ? false : true);
  }, []);

  switch (configs.parentCompany?.appType) {
    case "trucking":
      return (
        <Suspense fallback={<Spinner />}>
          <TruckingJobInfo
            job={job}
            {...props}
            legForm={form}
            form={props.form}
            showCreateModal={showCreateModal}
            createModalVisible={createModalVisible}
          />
        </Suspense>
      );
    case "service":
    default:
      return (
        <Suspense fallback={<Spinner />}>
          <ServiceRepairJobInfo
            job={job}
            {...props}
            serviceForm={form}
            form={props.form}
            showCreateModal={showCreateModal}
            createModalVisible={createModalVisible}
          />
        </Suspense>
      );
      return "Something went wrong, please contact support.";
  }
};

export default JobInfo;
