import { useDispatch } from "react-redux";
import { updateJob } from "../../../redux/reducers/jobs/jobSlice";
import { getTableConfig } from "./table.configs/show";
import { useAuth } from "../../../hooks/useAuth";
import { jobUpdated } from "../../../redux/reducers/jobs/jobsSlice";
import TableLayout from "../../../layouts/TableLayout";
import { useConfig } from "../../../hooks";

const JobDrops = ({ data }) => {
  const jobs = data.jobs?.map((item) => {
    return {
      ...item,
      // jobId: data._id,
    };
  });
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { appType } = useConfig();
  const { COLUMN_DEFINITIONS } = getTableConfig(appType);

  async function handleUpdate(e, setLoading) {
    const updateData = e.column.editable.update
      ? e.column.editable.update({ user, ...e })
      : {
          parent: user.parentCompany,
          jobId: e.row.original._id,
          data: e.data,
        };

    const res = await dispatch(updateJob(updateData)).unwrap();
    if (res.status === "success") {
      dispatch(jobUpdated(res.data));

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }
  const title = (
    <div>
      Jobs <span className="text-gray-500 text-lg">({jobs?.length})</span>
    </div>
  );

  if (!jobs) return "Loading...";
  return (
    <TableLayout
      id="jobs"
      title={title}
      data={jobs}
      hideActions
      columns={COLUMN_DEFINITIONS}
      onUpdate={handleUpdate}
      hideCheckbox
      fullSelectedItem
    />
  );
};

export default JobDrops;
