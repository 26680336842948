import React, { useState } from "react";
import { Avatar, Button, EditableText, Input } from "../shared";

export const DashboardPublishModal = ({ data }) => {
  const [inviteEmail, setInviteEmail] = useState("");
  const [dashboardLink, setDashboardLink] = useState("sales.untitledui.com");
  const [metadata, setMetadata] = useState([
    { key: "name", value: "Amélie Laurent" },
    { key: "email", value: "amelie@untitledui.com" },
    { key: "phone", value: "+33 6 00 00 00 00" },
    { key: "address", value: "123 Rue de la Paix, Paris, France" },
  ]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(dashboardLink);
    // You might want to add a toast notification here
  };

  const handleSendInvite = () => {
    // Implement invite sending logic here
    setInviteEmail("");
  };

  const handleAddMetadata = () => {
    setMetadata([...metadata, { key: "", value: "" }]);
  };

  const handleRemoveMetadata = (key) => {
    setMetadata(metadata.filter((item, idx) => Number(idx) !== Number(key)));
  };

  const handleGenerateQRCode = () => {};

  return (
    <div className="p-3 max-w-5xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">QR code overview</h2>
      <div className="grid grid-cols-5 gap-6">
        <div className="col-span-2">
          <h3 className="text-lg font-semibold mb-2">
            View or generate a QR code
          </h3>
          <p className="text-gray-600 mb-4">
            View or generate a QR code to view equipment details and history.
          </p>
          {/* <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Dashboard link
            </label>
            <div className="flex w-full">
              <Input
                value={dashboardLink}
                onChange={(e) => setDashboardLink(e.target.value)}
                className="rounded-r-none w-full"
                size="sm"
              />
              <Button
                size="xs"
                onClick={handleCopyLink}
                className="rounded-l-none"
              >
                Copy
              </Button>
            </div>
          </div>
          <Button size="xs" variant="text" className="text-blue-600">
            + Add custom domain
          </Button> */}
          <div className="mt-10 flex flex-col items-center">
            {data && (
              <p className="text-sm text-gray-600 mb-2">Scan to open in app</p>
            )}
            <div
              onClick={handleGenerateQRCode}
              className="bg-gray-100 p-4 inline-block rounded-lg hover:bg-gray-200 transition-colors duration-200 cursor-pointer"
            >
              {/* Replace with an actual QR code component or image */}
              <div className="w-32 h-32 bg-gray-300 text-gray-500 flex items-center justify-center text-center p-2 hover:bg-gray-400 transition-colors duration-300">
                Click here to generate
              </div>
            </div>
            {data && (
              <p className="text-xs text-gray-500 mt-2 text-center">
                Scan this code with your phone to
                <br />
                open your dashboard in the app.
              </p>
            )}
          </div>
        </div>
        <div className="col-span-3">
          <h3 className="text-lg font-semibold mb-2">Custom fields</h3>
          <p className="text-gray-600 mb-4">
            Use this to send additional information to anyone viewing the qr
            code.
          </p>
          {/* <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Invite email
            </label>
            <div className="flex">
              <Input
                size="sm"
                value={inviteEmail}
                onChange={(e) => setInviteEmail(e.target.value)}
                placeholder="Enter email address"
                className="rounded-r-none"
              />
              <Button
                onClick={handleSendInvite}
                className="rounded-l-none bg-primary-600 text-white"
                size="xs"
              >
                Send invite
              </Button>
            </div>
          </div> */}
          <div className="space-y-3">
            {Object.entries(metadata).map(([key, value], index) => {
              return (
                <div key={index} className="grid grid-cols-10 gap-2">
                  <div className="col-span-1">
                    <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center text-gray-600 font-medium">
                      {key.charAt(0).toUpperCase()}
                    </div>
                  </div>
                  <div className="col-span-3">
                    <EditableText
                      id={value.key}
                      value={value.key}
                      onSave={(saveData, setLoading) => {
                        // let newMetadata = [...metadata];
                        // Handle saving the new key
                        // const newKey = saveData.value;
                        // const oldValue = metadata[key];
                        // const newMetadata = { ...metadata };
                        // delete newMetadata[key];
                        // newMetadata[key] = oldValue;
                        // setMetadata(newMetadata);
                        // setLoading(false);
                      }}
                      textClasses="text-sm font-medium"
                    />
                  </div>
                  <div className="col-span-4">
                    <Input
                      size="sm"
                      value={value.value}
                      onChange={(e) =>
                        handleMetadataChange(key, e.target.value)
                      }
                      className="w-48"
                    />
                  </div>
                  <div className="col-span-2">
                    <Button
                      onClick={() => handleRemoveMetadata(key)}
                      className="ml-2 text-red-600"
                      size="xs"
                      variant="text"
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              );
            })}
            <Button
              onClick={handleAddMetadata}
              className="mt-2 text-blue-600"
              size="xs"
              variant="text"
            >
              + Add metadata field
            </Button>
          </div>
          {/* <div className="mt-6">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm font-medium">6/10 team seats used</span>
              <Button variant="text" className="text-blue-600">
                Manage
              </Button>
            </div>
            <div className="bg-gray-200 rounded-full h-2">
              <div className="bg-primary-600 rounded-full h-2 w-3/5"></div>
            </div>
            <p className="text-xs text-gray-500 mt-1">
              You can upgrade your account to add more users to your team.
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default DashboardPublishModal;
