import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Input from "../../shared/Input";
import useForm from "../../hooks/useForm";
import { Button, Modal, Select } from "../../shared";
import { AddressForm } from "../../components/Address";
import { useAddClientMutation } from "../../redux/services/clients/clientsApi";
import { FORM_OPTIONS } from "./table.config";
import { NewClientForm } from "./NewClientForm";

export const CreateClientModal = ({ visible, setVisible, onCancel }) => {
  const navigate = useNavigate();
  const { form, updateForm, clearForm, setFormInit, errors, isValid } =
    useForm(FORM_OPTIONS);

  const [addClient, { isLoading }] = useAddClientMutation();

  function closeModal() {
    clearForm();
    onCancel && onCancel(false);
    setVisible(false);
  }

  function updateAddress(e) {
    setFormInit({
      ...form,
      address: {
        ...form.address,
        [e.id]: e.value,
      },
    });
  }

  async function handleSave() {
    const res = await addClient(form).unwrap();
    toast(res.message, {
      toastId: "add-client-" + res.status,
      type: res.status,
    });

    if (res.status === "success") {
      navigate("/clients/" + res.data._id);
      closeModal();
    }
  }

  const footer = (
    <div>
      <Button type="alternative" className="mr-2" onClick={closeModal}>
        Cancel
      </Button>
      <Button
        id="submit-client"
        loading={isLoading}
        disabled={isLoading || !isValid}
        onClick={handleSave}
      >
        Submit
      </Button>
    </div>
  );

  const content = (
    <NewClientForm form={form} updateForm={updateForm} errors={errors} />
  );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onClose={closeModal}
      title="Create Client"
      footer={footer}
      content={content}
      size="md"
      footerPosition="right"
      contentWrapperclassName="p-3 lg:p-6 space-y-6"
    />
  );
};
