import {
  COLUMN_DEFINITIONS as serviceColumnDefs,
  FORM_OPTIONS as serviceFormOptions,
  PAGE_SIZE_OPTIONS as servicePageSizeOptions,
} from "./service.table.config";
import {
  COLUMN_DEFINITIONS as truckingColumnDefs,
  FORM_OPTIONS as truckingFormOptions,
  PAGE_SIZE_OPTIONS as truckingPageSizeOptions,
} from "./trucking.table.config";

/**
 * Returns the appropriate table configuration based on the job type
 * @param {string} type - The type of job ('serviceRepair' or 'trucking')
 * @returns {Object} The table configuration object containing columnDefs, formOptions, and pageSizeOptions
 */
const getTableConfig = (type) => {
  switch (type) {
    case "serviceRepair":
      return {
        COLUMN_DEFINITIONS: serviceColumnDefs,
        FORM_OPTIONS: serviceFormOptions,
        PAGE_SIZE_OPTIONS: servicePageSizeOptions,
      };
    case "trucking":
      return {
        COLUMN_DEFINITIONS: truckingColumnDefs,
        FORM_OPTIONS: truckingFormOptions,
        PAGE_SIZE_OPTIONS: truckingPageSizeOptions,
      };
    default:
      return {};
  }
};

export default getTableConfig;
