import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./reducers/accountSlice";
import dashboardReducer from "./reducers/dashboard";
import drivers from "./reducers/drivers";
// import employees from "./reducers/employees";
import mapReducer from "./reducers/map";
import jobs from "./reducers/jobs";
import clients from "./reducers/clients";
import customers from "./reducers/customers";
import feedbacks from "./reducers/feedbacks";
import users from "./reducers/users";
import messages from "./reducers/messages";
import media from "./reducers/media";
import locations from "./reducers/locations";
import invoices from "./reducers/invoices";
import payouts from "./reducers/payouts";
import legs from "./reducers/legs";
import organizations from "./reducers/organizations";
import manifests from "./reducers/manifests";
// import reports from "./services/reports/reportsApi";

import { api } from "./api";

export default configureStore({
  middleware: (gDM) =>
    gDM({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([api.middleware]),
  // devTools: import.meta.env.NODE_ENV !== "production",
  reducer: {
    account: accountReducer,
    dashboard: dashboardReducer,
    map: mapReducer,
    // ...employees,
    ...drivers,
    ...jobs,
    ...invoices,
    ...clients,
    ...customers,
    ...feedbacks,
    ...users,
    ...manifests,
    ...messages,
    ...media,
    ...locations,
    ...invoices,
    ...payouts,
    ...legs,
    ...organizations,
    // ...reports,
    [api.reducerPath]: api.reducer,
  },
});
