import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { formatDate } from ".";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function formatTimezone(
  date,
  tz = timezone,
  format = "yyyy-MM-dd HH:mm:ss zzz",
  options
) {
  return formatInTimeZone(date, tz, format, options);
}

/**
 * Updates the date part of a Date object.
 * @param {Date} date - The Date object to update.
 * @param {Date} newDate - The new date as a Date object.
 * @returns {Date} The updated Date object.
 */
export const updateDate = (date, newDate) => {
  if (!newDate) return null;
  const updatedDate = new Date(date); // Create a copy of the original date to avoid modifying it directly.
  updatedDate.setFullYear(
    newDate.getFullYear(),
    newDate.getMonth(),
    newDate.getDate()
  );
  return updatedDate;
};

/**
 * Updates the time part of a Date object.
 * @param {Date} date - The Date object to update.
 * @param {string} newTime - The new time in 'HH:MM' format.
 * @returns {Date} The updated Date object.
 */
export const updateTime = (date, newTime) => {
  if (!newTime) return null;
  const [hours, minutes] = newTime.split(":");
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
};

/**
 * Formats a Date object for display with optional AM/PM format.
 * @param {Date} date - The Date object to format.
 * @param {string} type - The type to use. 'date' for YYYY-MM-DD, 'time' for HH:MM, 'timeAMPM' for HH:MM AM/PM.
 * @returns {string} The formatted date or time.
 */
export const formatDateOrTime = (date, type) => {
  if (!date) return "";
  if (type === "date") {
    return formatDate(date);
  } else if (type === "time") {
    return format(date, "HH:mm");
  } else if (type === "timeAMPM") {
    return format(date, "hh:mm a");
  }
  return "";
};
