import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../../../shared/Table/table.utils";
import { renderAmount, formatDate } from "../../../../utils";
// import { jobTypeOptions } from "../../../Jobs/select.config";

const tableConfig = [
  {
    id: "jobId",
    header: "Job ID",
    visible: true,
    required: true,
    minWidth: 180,
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/jobs/${original._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  //   {
  //     id: "jobType",
  //     minWidth: 140,
  //     editable: {
  //       type: "select",
  //       options: jobTypeOptions,
  //     },
  //     cell: ({ getValue }) => getValue(),
  //   },
  {
    id: "hours",
    minWidth: 100,
    cell: ({ getValue }) => getValue(),
  },
  {
    id: "totalPay",
    cell: ({ getValue }) => renderAmount(getValue()),
    minWidth: 140,
  },
  {
    id: "date",
    cell: ({ getValue }) => formatDate(getValue(), null, true),
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
