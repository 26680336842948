import { combineCSVFiles } from "../csvCombiner";
import { equipment, inventoryItems, maintenanceHistory } from "./handlers";

/**
 * Combines and formats service records from multiple CSV files into a structured format
 *
 * @param {File[]} files - Array of CSV files to process
 * @param {Object} options - Configuration options for processing
 * @param {string[]} options.selectedHeaders - List of headers/columns to include in final output
 * @param {string} options.groupByKey - Header used to group related rows (typically Serial No.)
 * @param {Object} options.groupingRules - Rules for how to combine/merge values when grouping rows
 * @param {Object} options.keyMappings - Mappings to standardize header names across files
 * @returns {Object} Object containing:
 *   - data: Formatted service records grouped by serial number
 *   - headers: Final list of headers used
 *   - allAvailableHeaders: All headers found across files
 *   - keysUsed: Which key mappings were applied
 *   - summary: Statistics about the processed data
 */
export const formatServiceRecords = async (
  files,
  { selectedHeaders, groupByKey, groupingRules, keyMappings }
) => {
  // Process files using combineCSVFiles utility with custom grouping
  // for equipment, maintenance history and inventory items
  const result = await combineCSVFiles(files, {
    selectedHeaders, // Only include specified columns
    groupByKey, // Group by serial number or other key
    groupingRules, // Rules for combining duplicate values
    keyMappings, // Standardize header names
    customGroups: {
      equipment, // Group equipment-related fields
      maintenanceHistory, // Track maintenance records
      inventoryItems, // Track inventory/parts used
    },
  });

  return result;
};
