import React, {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useSigninMutation,
  useCurrentUserQuery,
  useLogoutMutation,
} from "../redux/services/account/accountApi";
import { toast } from "react-toastify";
import { PARENT_COMPANY, USER, APP_TYPE } from "../config";
import { useLocalStorage } from "./useLocalStorage";
import { PageLoading } from "../shared";
import { useConfig } from "./useConfig";
import { clearApiCache } from "../redux/api";

const AuthContext = createContext({
  user: null,
  loading: true,
  logout: async () => {},
  login: async () => ({ success: false }),
  isAuthenticated: false,
  initialized: false,
});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useLocalStorage(USER, null);
  const { cleanUpConfig } = useConfig();
  const [_parentCompany, setParentCompany] = useLocalStorage(
    PARENT_COMPANY,
    null
  );

  const [signinMutation, { isLoading: signinLoading }] = useSigninMutation();
  const [logoutMutation, { isLoading: logoutLoading }] = useLogoutMutation();

  // Skip the current user query if we're on the login page
  const skipCurrentUser = location.pathname === "/login" && !user;

  const { data: currentUser, isLoading: currentUserLoading } =
    useCurrentUserQuery(undefined, {
      skip: !initialized || skipCurrentUser,
    });

  const isLoading = signinLoading || logoutLoading || currentUserLoading;
  const isAuthenticated = !!user?.active;

  // Handle initialization
  useEffect(() => {
    if (!initialized && !currentUserLoading) {
      setInitialized(true);
    }
  }, [currentUserLoading]);

  // Handle current user updates
  useEffect(() => {
    if (!initialized) return;

    if (currentUser) {
      setUser(currentUser.user);
      setParentCompany(
        currentUser.user.parentCompany || currentUser.user.organization
      );
    } else if (initialized && !currentUserLoading && !skipCurrentUser) {
      setUser(null);
      setParentCompany(null);
    }
  }, [currentUser, currentUserLoading, initialized]);

  // Handle authentication state changes
  useEffect(() => {
    if (!initialized) return;

    const isLoginPage = location.pathname === "/login";

    if (user && isLoginPage) {
      // Redirect authenticated users away from login page
      const intendedPath = location.state?.from?.pathname || "/";
      navigate(intendedPath, { replace: true });
    } else if (!user && !isLoginPage && !currentUserLoading) {
      // Redirect unauthenticated users to login page
      navigate("/login", {
        replace: true,
        state: { from: location },
      });
    }
  }, [user, initialized, location.pathname]);

  const loginUser = async (credentials) => {
    try {
      const payload = await signinMutation({
        user: credentials,
      }).unwrap();

      if (payload.status === "success") {
        setUser(payload.user);
        setParentCompany(
          payload.user?.parentCompany || payload.user?.organization
        );
        return { success: true };
      }
      toast.error(payload.message || "Invalid credentials");
      return { success: false, error: payload.message };
    } catch (error) {
      toast.error("An error occurred during login");
      return { success: false, error };
    }
  };

  const logoutUser = async () => {
    try {
      await logoutMutation().unwrap();
      clearApiCache();
      setUser(null);
      setParentCompany(null);
      cleanUpConfig();
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login", { replace: true });
    } catch (error) {
      toast.error("An error occurred during logout");
    }
  };

  const value = useMemo(
    () => ({
      user,
      loading: isLoading,
      logout: logoutUser,
      login: loginUser,
      isAuthenticated,
      initialized,
    }),
    [user, isAuthenticated, isLoading, initialized]
  );

  if (!initialized && isLoading) {
    return <PageLoading />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
