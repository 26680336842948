import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../shared/Table/table.utils";
import { Badge } from "../../shared";

const tableConfig = [
  {
    id: "fullName",
    // Accessing nested user.fullName
    accessorFn: (row) => row.user?.fullName,
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/employees/${original._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  {
    id: "email",
    // Accessing nested user.email
    accessorFn: (row) => row.user?.email,
    cell: ({ getValue }) => getValue(),
  },
  {
    id: "phone",
    // Accessing nested user.phone
    accessorFn: (row) => row.user?.phone,
    cell: ({ getValue }) => getValue(),
  },
  {
    id: "role",
    accessorFn: (row) => row.user?.role || row.role,
    cell: ({ getValue }) => getValue(),
    editable: {
      type: "select",
      options: [
        { value: "Admin", label: "Admin" },
        { value: "Manager", label: "Manager" },
        { value: "Employee", label: "Employee" },
        { value: "Technician", label: "Technician" },
      ],
    },
  },
  //   {
  //     id: "organization",
  //     cell: ({ getValue }) => {
  //       const org = getValue();
  //       if (!org) return null;
  //       return <Badge label={org.name} link={`/organizations/${org._id}`} />;
  //     },
  //   },
  {
    id: "status",
    accessorFn: (row) => row.status,
    cell: ({ getValue }) => getValue(),
    editable: {
      type: "select",
      options: [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
      ],
    },
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
export const FORM_OPTIONS = { validations: renderValidations(tableConfig) };
export const PAGE_SIZE_OPTIONS = [
  { label: "10 Employees", value: 10 },
  { label: "20 Employees", value: 20 },
  { label: "50 Employees", value: 50 },
  { label: "100 Employees", value: 100 },
];

export default { COLUMN_DEFINITIONS, FORM_OPTIONS, PAGE_SIZE_OPTIONS };
