import { Badge } from "../../../shared";
import { renderCustomColumnDefs } from "../../../shared/Table/table.utils";
import {
  capitalizeFirstLetter,
  formatDate,
  renderAmount,
} from "../../../utils";

/**
 * Truncates text and adds ellipsis if it exceeds the specified length
 * @param {string} text - The text to truncate
 * @param {number} totalLength - The total length of the truncated text
 * @returns {string|JSX.Element} - Truncated text with ellipsis or original text
 */
const truncateText = (text, totalLength = 75) => {
  if (!text) return "N/A";

  if (text.length <= totalLength) return text;

  return (
    <div
      title={text}
      style={{
        display: "-webkit-box",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        wordBreak: "break-word",
        lineHeight: "1.2em",
        maxHeight: "3.6em",
      }}
    >
      {text.substring(0, totalLength)}...
    </div>
  );
};

// Config for table and form
const tableConfig = [
  {
    id: "serviceType",
    header: "Service Type",
    enableGlobalFilter: true,
    visible: true,
    cell: (info) => info.getValue() || "N/A",
    minWidth: 140,
  },
  {
    id: "jobId",
    header: "Job ID",
    minWidth: 120,
    visible: false,
  },
  {
    id: "priority",
    header: "Priority",
    minWidth: 120,
    cell: ({ getValue }) => {
      const value = getValue();
      return <Badge label={value} />;
    },
  },
  {
    id: "status",
    header: "Status",
    enableGlobalFilter: true,
    visible: true,
    cell: (info) => {
      const value = info.getValue();
      return <Badge label={capitalizeFirstLetter(value)}></Badge>;
    },
  },
  {
    id: "performedBy",
    header: "Performed By",
    enableGlobalFilter: true,
    visible: true,
    cell: ({ getValue }) => {
      const performers = getValue();
      return performers.length > 0 ? performers.join(", ") : "Not Performed";
    },
    minWidth: 140,
  },
  {
    id: "startDate",
    header: "Start Date",
    minWidth: 170,
    visible: false,
    cell: ({ getValue }) => {
      const value = getValue();
      return value && formatDate(value, null, true);
    },
  },
  {
    id: "endDate",
    header: "End Date",
    minWidth: 170,
    visible: false,
    cell: ({ getValue }) => {
      const value = getValue();
      return value && formatDate(value, null, true);
    },
  },
  {
    id: "recommendations",
    header: "Recommendations",
    minWidth: 200,
    visible: false,
  },
  {
    id: "laborTime",
    header: "Labor Hours",
    minWidth: 120,
    cell: ({ getValue }) => {
      const value = getValue();
      return value ? `${value} hrs` : "";
    },
  },
  {
    id: "summary",
    header: "Summary",
    enableGlobalFilter: true,
    visible: true,
    cell: (info) => truncateText(info.getValue(), 100),
    minWidth: 200,
  },
  {
    id: "parts",
    header: "Parts",
    enableGlobalFilter: false,
    visible: true,
    cell: ({ getValue }) => {
      const parts = getValue();
      if (!Array.isArray(parts) || parts.length === 0) {
        return "No Parts";
      }

      const totalParts = parts.length;
      const totalCost = parts.reduce((sum, part) => {
        return sum + (typeof part.cost === "number" ? part.cost : 0);
      }, 0);

      return (
        <div
          title={parts
            .map((part) => `${part.name || "Unknown"} (${part.quantity || 0})`)
            .join(", ")}
        >
          {`${totalParts} ${
            totalParts === 1 ? "Part" : "Parts"
          } - $${totalCost.toFixed(2)}`}
        </div>
      );
    },
    minWidth: 100,
  },
];

export default renderCustomColumnDefs(tableConfig);
