import React from "react";
import { Link, useNavigate } from "react-router-dom";

const menuItems = [
  {
    title: "Customers",
    path: "/admin/customers",
    desc: "All attune customer profiles",
  },
  {
    title: "Users",
    path: "/admin/users",
    desc: "All attune users profiles",
  },
  {
    title: "Support",
    path: "/admin/feedback",
    desc: "All attune customer tickets",
  },
  {
    title: "CSV Handler",
    path: "/admin/csv-handler",
    desc: "Process multiple CSV files",
  },
];

const SuperAdmin = (props) => {
  const navigate = useNavigate();

  function handleNavigate(path) {
    navigate(path);
  }

  function renderMenuItems(item) {
    return (
      <div
        onClick={() => handleNavigate(item.path)}
        className="block cursor-pointer max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100"
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          {item.title}
        </h5>
        <p className="text-gray-500 font-medium">{item.desc}</p>
      </div>
    );
  }

  return (
    <div>
      <div className="grid grid-cols-4 gap-4 p-2">
        {menuItems.map((item) => renderMenuItems(item))}
      </div>
    </div>
  );
};

export default SuperAdmin;
