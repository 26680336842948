import { Input } from "../../shared";
import { AddressForm } from "../../components/Address";

export const NewClientForm = ({ form, updateForm, errors }) => {
  return (
    <div className="space-y-4">
      <div>
        <div className="text-lg font-medium text-gray-900 mb-2">Details</div>
        <div className="grid grid-cols-2 gap-3">
          <Input
            id="name"
            value={form.name}
            label="Client Name"
            placeholder="Client Name"
            onChange={updateForm}
            error={errors.name}
          />
          <Input
            id="email"
            value={form.email}
            label="Email"
            placeholder="Email"
            onChange={updateForm}
            error={errors.email}
          />
          <Input
            id="phone"
            value={form.phone}
            label="Phone"
            placeholder="Phone"
            onChange={updateForm}
            error={errors.phone}
          />
        </div>
      </div>
      <div>
        <div className="text-lg font-medium text-gray-900 mb-2">Address</div>
        <AddressForm
          form={form}
          errors={errors}
          updateForm={updateForm}
          inputSize="md"
        />
      </div>
    </div>
  );
};
