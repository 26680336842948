import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import TableLayout from "../../layouts/TableLayout/NewTableLayout";
import { useAuth } from "../../hooks/useAuth";
import {
  useGetEquipmentQuery,
  // useUpdateEquipmentMutation,
} from "../../redux/services/equipment";
import { usePrefetch } from "../../redux/api";
import { useDebounceSearch } from "../../hooks";
import { removeEmptyValues } from "../../utils";
import { isAuthorized } from "../../utils/authority";
import { COLUMN_DEFINITIONS } from "./table.config";
import { DeleteEquipmentModal } from "./DeleteModal";
import EquipmentCreateModal from "./CreateModal";

const Equipment = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [deselectItems, setDeselectItems] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const sendSelectedItems = useCallback((value) => setSelectedItems(value), []);

  const { search, handleSearch } = useDebounceSearch();

  const [filters, setFilters] = useState([]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const searchFilters = useMemo(() => {
    return filters.reduce((a, v) => {
      if (v.id === "date") {
        return {
          ...a,
          startDate: v.value?.start.toISOString(),
          endDate: v.value?.end.toISOString(),
        };
      }
      return { ...a, [v.id]: v.value };
    }, {});
  }, [filters]);

  const {
    data: equipmentData,
    isLoading,
    isFetching,
  } = useGetEquipmentQuery({
    page: pageIndex + 1,
    limit: pageSize,
    search: search.useSearch,
    searchTerm: search.term,
    ...removeEmptyValues(searchFilters),
  });

  const data = useMemo(() => {
    if (!equipmentData?.equipment) return [];
    return equipmentData.equipment.filter(
      (item) => item !== null && item !== undefined
    );
  }, [equipmentData]);

  const paginationData = useMemo(
    () => ({
      hasNext: equipmentData?.hasNext || false,
      hasPrev: equipmentData?.hasPrev || false,
      totalPages: equipmentData?.totalPages || 0,
      page: equipmentData?.page || 1,
      count: equipmentData?.count || 0,
    }),
    [equipmentData]
  );

  // const [updateEquipment, { isLoading: equipmentUpdating }] =
  //   useUpdateEquipmentMutation();

  const updateEquipment = () => {};

  const prefetchPage = usePrefetch("getEquipment");

  const prefetchNext = useCallback(() => {
    prefetchPage({
      page: data.nextPage,
      limit: pageSize,
      search: search.useSearch,
      searchTerm: search.term,
      ...removeEmptyValues(searchFilters),
    });
  }, [
    prefetchPage,
    data?.nextPage,
    pageSize,
    search.useSearch,
    search.searchTerm,
  ]);

  useEffect(() => {
    if (data?.hasNext && data.nextPage <= data.totalPages) {
      prefetchNext();
    }
  }, [data, pageIndex, prefetchNext]);

  const [createModalOpen, setCreateModalOpen] = useState(false);

  const handleEdit = useCallback(
    (itemId) => {
      navigate(`/equipment/${itemId}`);
    },
    [navigate]
  );

  const handleUpdate = useCallback(
    async (e, setLoading) => {
      const updateData = {
        id: e.row.original._id,
        data: e.data,
      };

      const res = await updateEquipment(updateData).unwrap();

      toast(res.message, {
        type: res.status,
        toastId: `update-equipment-${res.status}`,
      });
      setTimeout(() => {
        setLoading(false);
      }, 500);
    },
    [updateEquipment]
  );

  const handleDeleteClick = useCallback(() => {
    setDeleteVisible(true);
  }, []);

  const cannotCreateOrEdit = !isAuthorized("owner, admin");

  const globalFilter = useCallback(
    (value) => {
      setPagination({ pageIndex: 0, pageSize });
      handleSearch(value);
    },
    [pageSize, handleSearch]
  );

  const filterTypes = useMemo(
    () => [
      {
        id: "date",
        type: "date",
        label: "Date",
        defaultText: "All Time",
        defaultValue: {
          id: "allTime",
          value: null,
        },
      },
      // Add more filter types specific to equipment if needed
    ],
    []
  );

  return (
    <div className="">
      <TableLayout
        id="equipment"
        title={t("equipment.main")}
        buttonSize="xs"
        loading={isLoading}
        columns={COLUMN_DEFINITIONS} // Define COLUMN_DEFINITIONS for equipment
        data={data}
        pagination={paginationData}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPaginationChange={setPagination}
        pageSizeOptions={[10, 20, 30, 40, 50]} // Define PAGE_SIZE_OPTIONS for equipment
        handleEdit={handleEdit}
        onUpdate={handleUpdate}
        mainAction={{ onClick: () => setCreateModalOpen(true) }}
        hideActions={cannotCreateOrEdit}
        hideMainAction={cannotCreateOrEdit}
        globalSearchFilters={["date", "equipmentNumber"]} // Adjust as needed for equipment
        fullTextSearch={globalFilter}
        sendSelectedItems={sendSelectedItems}
        handleDeleteClick={handleDeleteClick}
        filters={filterTypes}
        onFiltersChange={setFilters}
        hideCheckbox={!isAuthorized("owner,admin")}
      />
      <EquipmentCreateModal
        modalOpen={createModalOpen}
        setModalOpen={setCreateModalOpen}
      />
      <DeleteEquipmentModal
        deselectItems={() => {
          setDeselectItems(true);
          setTimeout(() => setDeselectItems(false), 500);
        }}
        visible={deleteVisible}
        setVisible={setDeleteVisible}
        equipment={selectedItems}
      />
    </div>
  );
};

export default React.memo(Equipment);
