import React, { useState } from "react";
import {
  useGetInventoryItemsQuery,
  useDeleteInventoryItemMutation,
  useUpdateInventoryItemMutation,
} from "../../redux/services/inventory/inventoryApi";
import CreateModal from "./CreateModal";
import { Button } from "../../shared";
import TableLayout from "../../layouts/TableLayout/NewTableLayout";
import { toast } from "react-toastify";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./table.config";
import { useDebounceSearch } from "../../hooks";

const InventoryIndex = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const { search, handleSearch } = useDebounceSearch();
  // const [filters, setFilters] = useState([]);

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  // const searchFilters = filters.reduce((a, v) => {
  //   if (v.id === "quantity") {
  //     return {
  //       ...a,
  //       startDate: v.value?.start.toISOString(),
  //       endDate: v.value?.end.toISOString(),
  //     };
  //   }
  //   return { ...a, [v.id]: v.value };
  // }, {});

  const { data, isLoading, isFetching, isError, error } =
    useGetInventoryItemsQuery({
      page: pageIndex + 1,
      limit: pageSize,
      search: search.useSearch,
      searchTerm: search.term,
    });

  // Handle modal close
  const handleCloseModal = () => {
    setCreateModalOpen(false);
  };

  // Handle item creation success
  const handleCreateSuccess = (message) => {
    toast.success(message);
    handleCloseModal();
  };

  // Handle item creation error
  const handleCreateError = (message) => {
    toast.error(message);
  };

  // Handle delete function
  const handleDelete = async (id) => {
    try {
      await deleteInventoryItem(id).unwrap();
      toast.success("Item deleted successfully");
    } catch (error) {
      toast.error(error?.data?.message || "Failed to delete item");
    }
  };

  // Handle edit function
  const handleEdit = async (id, data) => {
    try {
      await updateInventoryItem({ id, data }).unwrap();
      toast.success("Item updated successfully");
    } catch (error) {
      toast.error(error?.data?.message || "Failed to update item");
    }
  };

  function globalFilter(value) {
    setPagination({ pageIndex: 0, pageSize });
    handleSearch(value);
  }

  if (isError) {
    return (
      <div>
        Error: {error?.data?.message || "Failed to load inventory items"}
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Inventory Items</h1>
        <Button onClick={() => setCreateModalOpen(true)} type="primary">
          Create New Item
        </Button>
      </div>

      <TableLayout
        columns={COLUMN_DEFINITIONS}
        data={data?.inventory}
        loading={isLoading || isFetching}
        pagination={data}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPaginationChange={setPagination}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        fullTextSearch={globalFilter}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
      />

      {createModalOpen && (
        <CreateModal
          modalOpen={createModalOpen}
          setModalOpen={setCreateModalOpen}
          onSuccess={handleCreateSuccess}
          onError={handleCreateError}
        />
      )}
    </div>
  );
};

export default InventoryIndex;
