import Papa from "papaparse";
import {
  equipment,
  maintenanceHistory,
  inventoryItems,
} from "./data-upload/handlers";

/**
 * Combines multiple CSV files vertically, with option to group by key
 * @param {File[]} files - Array of CSV files
 * @param {Object} options - Configuration options
 * @param {string[]} options.selectedHeaders - Headers to include in output
 * @param {string} options.groupByKey - Header to group rows by (e.g., "Serial No.")
 * @param {Object} options.groupingRules - Rules for combining grouped values
 * @returns {Object} Combined data and summary
 */
export const combineCSVFiles = async (
  files,
  {
    selectedHeaders = null,
    groupByKey = null,
    groupingRules = {},
    customGroups = {},
    keyMappings = {},
  }
) => {
  let allHeaders = new Set();
  let combinedData = [];
  let customGroupsData = {};
  let groupedData = new Map();

  // Parse all files first
  const parsedFiles = await Promise.all(
    files.map(async (file) => {
      return new Promise((resolve) => {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            resolve(results.data);
          },
          error: (error) => {
            console.error("Parse error:", error);
            resolve([]);
          },
        });
      });
    })
  );

  // Get all unique headers and store them cleaned
  parsedFiles.forEach((fileData) => {
    if (fileData.length > 0) {
      Object.keys(fileData[0]).forEach((header) => {
        if (!header.startsWith('"') && !header.endsWith('"')) {
          header = `"${header}"`;
        }
        // Clean header: trim and remove quotes
        allHeaders.add(header.trim().replace(/^["']|["']$/g, ""));
      });
    }
  });

  // Improve header matching logic
  const finalHeaders = selectedHeaders
    ? selectedHeaders.filter((header) => {
        if (!header.startsWith('"') && !header.endsWith('"')) {
          header = `"${header}"`;
        }
        // Clean header: trim and remove quotes
        const cleanHeader = header.trim().replace(/^["']|["']$/g, "");
        return Array.from(allHeaders).some(
          (existingHeader) => existingHeader === cleanHeader
        );
      })
    : Array.from(allHeaders);

  // Helper function to check if header is a serial number field
  const isSerialHeader = (header) => header.toLowerCase().includes("serial");

  // Stack all rows from all files
  parsedFiles.forEach((fileData, fileIndex) => {
    fileData.forEach((row) => {
      const currentRow = {};

      const customGroupsKeys = Object.keys(customGroups);
      if (customGroupsKeys.length) {
        customGroupsKeys.forEach((key) => {
          const handler = customGroups[key];
          if (handler) {
            const { key: newKey, data: newData } = handler(row, {
              customGroupsData,
              fileData,
              selectedHeaders,
              allHeaders,
              keyMappings,
            });

            if (!customGroupsData[key]) {
              customGroupsData[key] = new Map([[newKey, newData]]);
            }
          }
        });
      } else {
        finalHeaders.forEach((header) => {
          if (!header.startsWith('"') && !header.endsWith('"')) {
            header = `"${header}"`;
          }
          // Clean the header for matching
          const cleanHeader = header.trim().replace(/^["']|["']$/g, "");

          // Find matching value in row
          const matchingKey = Object.keys(row).find((k) => {
            const cleanK = k.trim().replace(/^["']|["']$/g, "");
            // Match exact headers or both are serial number fields
            return (
              cleanK === cleanHeader ||
              (isSerialHeader(cleanK) && isSerialHeader(cleanHeader))
            );
          });

          // Use the matching key's value or empty string if no match
          currentRow[header] = matchingKey ? row[matchingKey] : "";
        });

        if (groupByKey) {
          // If grouping by a serial number field, find any matching serial field value
          const groupValue = isSerialHeader(groupByKey)
            ? Object.entries(currentRow).find(
                ([key, value]) => isSerialHeader(key) && value
              )?.[1] || ""
            : currentRow[groupByKey];

          if (groupValue) {
            if (!groupedData.has(groupValue)) {
              groupedData.set(groupValue, currentRow);
            } else {
              const existingRow = groupedData.get(groupValue);

              // Combine rows based on grouping rules
              finalHeaders.forEach((header) => {
                if (!header.startsWith('"') && !header.endsWith('"')) {
                  header = `"${header}"`;
                }
                const rule = groupingRules[header] || "keepFirst";

                switch (rule) {
                  case "concatenate":
                    // Combine text values with separator, filtering out empty values
                    existingRow[header] = [
                      existingRow[header],
                      currentRow[header],
                    ]
                      .filter(Boolean)
                      .join(" | ");
                    break;

                  case "sum":
                    // Sum numeric values, handling various number formats and types
                    const existingValue =
                      typeof existingRow[header] === "string"
                        ? parseFloat(
                            existingRow[header].replace(/[^0-9.-]/g, "")
                          )
                        : typeof existingRow[header] === "number"
                        ? existingRow[header]
                        : 0;

                    const newValue =
                      typeof currentRow[header] === "string"
                        ? parseFloat(
                            currentRow[header].replace(/[^0-9.-]/g, "")
                          )
                        : typeof currentRow[header] === "number"
                        ? currentRow[header]
                        : 0;

                    existingRow[header] = existingValue + newValue;
                    break;

                  case "keepLatest":
                    // Keep the new value if it exists and isn't empty
                    if (currentRow[header]?.trim()) {
                      existingRow[header] = currentRow[header];
                    }
                    break;

                  case "keepFirst":
                    // Keep existing value if it exists, otherwise use new value
                    if (!existingRow[header]?.trim()) {
                      existingRow[header] = currentRow[header];
                    }
                    break;

                  default:
                    // For unknown rules, keep the first value
                    if (!existingRow[header]) {
                      existingRow[header] = currentRow[header];
                    }
                    break;
                }
              });
            }
          }
        } else {
          combinedData.push(currentRow);
        }
      }
    });
  });

  // Then modify the conversion logic
  let finalCombinedData;
  if (Object.keys(customGroups).length === 0 && groupByKey) {
    finalCombinedData = Array.from(groupedData.values());
  } else {
    finalCombinedData = {};

    for (const [key, mapData] of Object.entries(customGroupsData)) {
      if (!mapData || !(mapData instanceof Map)) {
        finalCombinedData[key] = [];
        continue;
      }

      const convertMapToArray = (obj) => {
        if (obj instanceof Map) {
          return Array.from(obj.values());
        }

        if (obj && typeof obj === "object") {
          const converted = {};
          for (const [k, v] of Object.entries(obj)) {
            if (v instanceof Map) {
              converted[k] = Array.from(v.values());
            } else {
              converted[k] = convertMapToArray(v);
            }
          }
          return converted;
        }

        return obj;
      };

      finalCombinedData[key] = Array.from(mapData.values()).map(
        convertMapToArray
      );
    }
  }

  // Calculate summary
  const summary = {
    filesProcessed: files.length,
    totalColumns: finalHeaders.length,
  };

  if (Array.isArray(finalCombinedData)) {
    summary.totalRows = finalCombinedData.length;
    summary.totalRecords = finalCombinedData.length;
  } else {
    // Handle object data
    summary.totalRows = Object.values(finalCombinedData).reduce(
      (sum, arr) => sum + (Array.isArray(arr) ? arr.length : 0),
      0
    );

    // Add individual totals for each group
    Object.entries(finalCombinedData).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        summary[`total${key.charAt(0).toUpperCase() + key.slice(1)}`] =
          value.length;
      }
    });

    summary.totalRecords = summary.totalRows;
  }

  return {
    data: finalCombinedData,
    headers: finalHeaders,
    allAvailableHeaders: Array.from(allHeaders),
    keysUsed: keyMappings,
    summary,
  };
};

// Helper function to read file content
const readFileContent = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target.result);
    reader.onerror = (e) => reject(e);
    reader.readAsText(file);
  });
};

/**
 * Exports combined data as a JSON file
 * @param {Object} combinedData - Processed CSV data
 * @param {string} fileName - Name for the exported file
 */
export const exportToJSON = (combinedData, fileName = "combined-data") => {
  const dataStr = JSON.stringify(combinedData, null, 2);
  const dataBlob = new Blob([dataStr], { type: "application/json" });
  const url = URL.createObjectURL(dataBlob);

  const link = document.createElement("a");
  link.href = url;
  link.download = `${fileName}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

/**
 * Exports combined data as a CSV file
 * @param {Object} combinedData - Processed CSV data
 * @param {string} fileName - Name for the exported file
 */
export const exportToCSV = (combinedData, fileName = "combined-data") => {
  // Convert data to CSV string using Papa Parse
  const csv = Papa.unparse({
    fields: combinedData.headers,
    data: combinedData.data,
  });

  // Create blob and trigger download
  const dataBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(dataBlob);

  const link = document.createElement("a");
  link.href = url;
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

/**
 * Combines and formats service records from multiple CSV files
 * @param {File[]} files - Array of CSV files
 * @param {Object} options - Configuration options
 * @param {string[]} options.selectedHeaders - Headers to include in output
 * @param {string} options.groupByKey - Header to group rows by (usually Serial No.)
 * @returns {Object} Formatted service records and summary
 */
export const formatServiceRecords = async (
  files,
  { selectedHeaders, groupByKey, groupingRules, keyMappings }
) => {
  const result = await combineCSVFiles(files, {
    selectedHeaders,
    groupByKey,
    groupingRules,
    keyMappings,
    customGroups: {
      equipment,
      maintenanceHistory,
      inventoryItems,
    },
  });

  return result;
};

/**
 * Combines and formats client records from multiple CSV files
 * @param {File[]} files - Array of CSV files
 * @param {Object} options - Configuration options
 * @param {string[]} options.selectedHeaders - Headers to include in output
 * @param {string} options.groupByKey - Header to group rows by (usually Client ID or Email)
 * @returns {Object} Formatted client records and summary
 */
export const formatClientRecords = async (
  files,
  {
    selectedHeaders = null,
    groupByKey = '"Customer Name"', // Default to email for grouping
  }
) => {
  // Define how different fields should be handled when combining records
  const clientRecordRules = {
    // Client Information (keep most recent)
    '"Customer Name"': "keepLatest",
    '"Customer Phone"': "keepLatest",
    '"Customer Email Address"': "keepFirst",
    '"Customer Address"': "keepLatest",
    '"City"': "keepLatest",
    '"State"': "keepLatest",
    '"ZIP Code"': "keepLatest",
    '"Zip"': "keepLatest",

    // Equipment History (track as separate entries)
    '"Serial No."': "equipmentHistory",
    '"Make"': "equipmentHistory",
    '"Model"': "equipmentHistory",
    '"Unit No."': "equipmentHistory",
    '"Unit NO."': "equipmentHistory",
    '"Unit Information"': "equipmentHistory",
    '"Hour Meter reading"': "equipmentHistory",
    '"Hour Meter Reading"': "equipmentHistory",
    '"HR Meter Reading"': "equipmentHistory",
    '"Unit Type"': "equipmentHistory",

    // Financial Metrics (sum)
    '"Total Labor"': "sum",
    '"Service Charge"': "sum",
    '"Parts Total"': "sum",
  };

  const result = await combineCSVFiles(files, {
    selectedHeaders,
    groupByKey,
    groupingRules: {
      ...clientRecordRules,
      equipmentHistory: (existing, current, metadata) => {
        if (!existing) return [createEquipmentEntry(current, metadata)];

        // Check if equipment already exists
        const existingEquipment = existing.find(
          (eq) => eq.serialNumber === current
        );

        if (existingEquipment) {
          // Update existing equipment with latest data
          return existing.map((eq) =>
            eq.serialNumber === current
              ? updateEquipmentEntry(eq, metadata)
              : eq
          );
        }

        // Add new equipment
        return [...existing, createEquipmentEntry(current, metadata)];
      },
    },
  });

  // Format the combined data into a more structured format
  const formattedRecords = result.data.map((record) => ({
    client: {
      name: record['"Customer Name"'],
      email: record['"Customer Email Address"'],
      phone: record['"Customer Phone"'],
      address: {
        street: record['"Customer Address"'],
        city: record['"City"'],
        state: record['"State"'],
        zipCode: record['"ZIP Code"'],
      },
    },
    equipment: record.equipmentHistory || [],
    // metrics: {
    //   totalSpent:
    //     (parseFloat(record['"Total Labor"']) || 0) +
    //     (parseFloat(record['"Service Charge"']) || 0) +
    //     (parseFloat(record['"Parts Total"']) || 0),
    //   totalServiceCharges: parseFloat(record['"Service Charge"']) || 0,
    //   totalLaborCosts: parseFloat(record['"Total Labor"']) || 0,
    //   totalPartsCosts: parseFloat(record['"Parts Total"']) || 0,
    // },
    // lastServiceDate: getLastServiceDate(record.equipmentHistory),
  }));

  return {
    records: formattedRecords,
    summary: {
      ...result.summary,
      uniqueClients: formattedRecords.length,
      totalEquipment: formattedRecords.reduce(
        (acc, curr) => acc + (curr.equipment?.length || 0),
        0
      ),
    },
  };
};

// Helper functions for equipment handling
const createEquipmentEntry = (serialNumber, metadata) => ({
  serialNumber,
  manufacturer: metadata['"Make"'],
  model: metadata['"Model"'],
  name: metadata['"Unit No."'],
  odometer: metadata['"Hour Meter reading"'] || metadata['"HR Meter Reading"'],
  lastServiceDate: metadata['"Service Date"'] || null,
});

const updateEquipmentEntry = (existing, metadata) => ({
  ...existing,
  manufacturer: metadata['"Make"'] || existing.manufacturer,
  model: metadata['"Model"'] || existing.model,
  name: metadata['"Unit No."'] || existing.name,
  odometer:
    metadata['"Hour Meter reading"'] ||
    metadata['"HR Meter Reading"'] ||
    existing.odometer,
  lastServiceDate: metadata['"Service Date"'] || existing.lastServiceDate,
});

const getLastServiceDate = (equipmentHistory) => {
  if (!equipmentHistory?.length) return null;

  return equipmentHistory.reduce((latest, equipment) => {
    if (!equipment.lastServiceDate) return latest;
    return !latest || new Date(equipment.lastServiceDate) > new Date(latest)
      ? equipment.lastServiceDate
      : latest;
  }, null);
};

const getClient = (record) => {
  record = Object.entries(record).reduce((acc, [key, value]) => {
    const trimmedKey = key.trim().replace(/^["']|["']$/g, "");
    acc[`${trimmedKey}`] = value;
    return acc;
  }, {});

  const name = record["Customer Name"];
  const email = record["Customer Email Address"];
  const phone = record["Customer Phone"];

  const address = record["Customer Address"];
  const city = record["City"];
  const state = record["State"];
  const zipCode = record["ZIP Code"] || record["Zip"];

  const uniqueId = `${name}`;

  return {
    key: uniqueId,
    data: {
      name,
      email,
      phone,
      address: {
        address,
        city,
        state,
        zipCode,
      },
    },
  };
};

const getEquipment = (record) => {
  record = Object.entries(record).reduce((acc, [key, value]) => {
    const trimmedKey = key.trim().replace(/^["']|["']$/g, "");
    acc[`${trimmedKey}`] = value;
    return acc;
  }, {});

  const serialNumber =
    record["Serial No."] || record["Serial"] || record["Serial NO."];

  const name =
    record["Unit No."] || record["Unit NO."] || record["Unit Information"];

  const make = record["Make"];
  const model = record["Model"];

  const odometer =
    record["Hour Meter reading"] ||
    record["HR Meter Reading"] ||
    record["Hour Meter Reading"];

  const uniqueId = `${serialNumber}-${name}-${make}-${model}`;

  return {
    key: uniqueId,
    data: {
      serialNumber,
      name,
      make,
      model,
      odometer,
      type: record["Unit Type"],
    },
  };
};

const getInventoryItem = (record) => {
  record = Object.entries(record).reduce((acc, [key, value]) => {
    const trimmedKey = key.trim().replace(/^["']|["']$/g, "");
    acc[`${trimmedKey}`] = value;
    return acc;
  }, {});

  const partNumber = record["Part Number"] || record["Part/Service Number"];
  const description =
    record["Description"] || record["Part/Service Description"];
  const quantity = record["Qty"] || record["QTY "];

  const uniqueId = `${partNumber}-${description}`;

  return {
    key: uniqueId,
    data: {
      partNumber,
      description,
      quantity,
    },
  };
};

const getMaintenanceHistory = (record) => {
  record = Object.entries(record).reduce((acc, [key, value]) => {
    const trimmedKey = key.trim(); //.replace(/^["']|["']$/g, "");
    acc[`${trimmedKey}`] = value;
    return acc;
  }, {});

  const originalCall =
    record["Original Call"] || record["Original Customer Concern"];

  let summary =
    record["Work Performed"] ||
    record["Job Description"] ||
    record["Description of Repairs Made"];

  const submissionForm = record["Submission Form Name"];

  if (submissionForm === "Planned Maintenance Order") {
    summary =
      "CHECK OPERATION OF CONTROLS, CLEAN UNIT WITH AIR PRESSURE, CHANGE AIR FILTER, CHECK BREAKS/ EMERGENCY BREAK, CHECK FOR HYDRAULIC LEAKS, CHECK FOR ENGINE OIL LEAKS, CHECK TRANSMISSION OPERATION, WIRING, HORN, FUSES, GAUGES, CHECK FUEL SYSTEM, CHECK RADIATOR/ WATER LEAKS, CHECK STEERING AND LINKAGE, HYDRAULIC/TRANSMISSION FLUIDS, BRAKE/ COOLANT FLUID LEVEL, CHANGE MOTOR OIL/ FILTER, GREASE ALL FITTINGS, CHECK BATTERY/ CLEAN TERMINALS, CHECK MAST ASSEMBLY/ CARRIAGE, CHECK DRIVE AND STEER TIRES, VISUAL INSPECTION/ MISCELLANEOUS, TEST DRIVE UNIT";
  }

  if (
    submissionForm === "PM Work Order OchoaMH" ||
    submissionForm === "PM Work Order"
  ) {
    summary =
      "Test Drive Unit, check operation of controls. Clean entire unit with air pressure. Check for hydraulic leaks. Check brake system. Check steering gear and linkage. Check mast assemble, carriage, rollers, chains and forks. Check all contact assemblies, switches, segments, springs and shunts in control circuit. Check hydraulic oil level. Check differential housing and steering system. Check fuse, all connections and inspect for faulty wiring. Visually inspect unit for worn parts, broken bolts, damage parts etc. Lubricate all grease fittings. Check battery connector, and battery water level. Inspect battery and battery compartment for acid leaks and corrosion. Check tires, load and caster wheels for ware and damage. Inspect steering, axle and tie rod ends. Test drive unit after PM.";
    // ("Test drive unit and check operation after PM.	Test Drive Unit, check operation of controls. Clean entire unit with air pressure. Check for hydraulic leaks. Check brake system. Check steering gear and linkage. Check mast assemble, carriage, rollers, chains and forks. Check all contact assemblies, switches, segments, springs and shunts in control circuit. Check hydraulic oil level. Check differential housing and steering system. Check fuse, all connections and inspect for faulty wiring. Visually inspect unit for worn parts, broken bolts, damage parts etc. Lubricate all grease fittings. Check battery connector, and battery water level. Inspect battery and battery compartment for acid leaks and corrosion. Check tires, load and caster wheels for ware and damage. Inspect steering, axle and tie rod ends. Test drive unit after PM.");
  }

  const recommendations = record["Recommendations"];
  const performedBy = record["Technician"] || record["Technician"];
  const appointmentDate = record["Date"] || record["Service Date"];
  const hours = record["Labor"] || record["Label Time "];
  const adjustedHours = record["Total Labor"];

  const uniqueId = `${originalCall}-${appointmentDate}`;

  return {
    key: uniqueId,
    data: {
      originalCall,
      summary,
      recommendations,
      performedBy,
      appointmentDate,
      hours,
      adjustedHours,
    },
  };
};
