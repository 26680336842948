import { AssignedToSelect } from "./AssignedToSelect";
import { useGetEmployeesQuery } from "../redux/services/employees/employeesApi";

export const AssignUserForm = ({ form, updateForm }) => {
  const { data: employees } = useGetEmployeesQuery({ role: "Technician" });
  return (
    <div className="flex flex-col items-center justify-center">
      <p className="mb-4 text-gray-800">
        Please ensure a technician is assigned before proceeding to the next
        step.
      </p>
      <AssignedToSelect
        onChange={(value) => updateForm({ id: "assignedTo", value })}
        value={form.assignedTo}
        users={employees?.employees || []}
      />
    </div>
  );
};
