import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { useState, useRef } from "react";
import { useEffect } from "react";
import Spinner from "../Spinner";
import { Button } from "../Button";

export const Input = React.forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value || "");
  const [showError, setShowError] = useState(false);
  const isIconReactElement = React.isValidElement(props.icon);

  useEffect(() => {
    props.value && setValue(props.value);
    !props.value && setValue("");
  }, [props.value]);

  const sizeLg = props.size === "lg";
  const sizeMd = props.size === "md" || !props.size;
  const sizeSm = props.size === "sm";

  const labelClasses = classNames(
    "block mb-1",
    "text-sm font-medium",
    props.labelClasses,
    {
      "text-gray-900": !showError,
      "text-red-700": showError,
    }
  );

  const wrapperClasses = classNames("block", props.wrapperClasses);

  const classes = classNames(
    "shadow-sm bg-white",
    // "focus-visible:ring-1 focus:ring-1",
    "block w-full focus:outline-none focus-visible:ring-primary-500",
    "border rounded-lg bg-transparent",
    { "p-4 text-md": sizeLg },
    { "p-2.5 text-sm": sizeMd },
    { "p-2 text-xs": sizeSm },
    {
      "border-gray-300 focus:ring-primary-500 focus:border-primary-500 text-gray-900":
        !showError,
    },
    {
      "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:outline-2 focus:ring-red-500 focus:border-red-500 focus-visible:ring-red-500 focus-visible:border-red-500":
        showError,
    },
    { "pl-8": props.icon && !isIconReactElement && props.icon.left },
    props.className
  );

  const iconClasses = classNames(
    "flex absolute inset-y-0 right-0 items-center px-3",
    {
      "mr-6": props.type === "number",
    }
  );

  function handleBlur(e) {
    if (props.error) {
      setShowError(true);
    } else {
      setShowError(false);
    }

    props.onBlur && props.onBlur(e);
  }

  function handleChange(e) {
    if (!props.error) setShowError(false);
    props.onChange && props.onChange(e.target);
    setValue(e.target.value);
  }

  function clearValue() {
    props.onChange && props.onChange("");
    setValue("");
  }

  return (
    <div className={wrapperClasses}>
      {props.label && (
        <label htmlFor={props.id} className={labelClasses}>
          {props.label}
        </label>
      )}
      <div className="relative w-full">
        {((props.icon && !isIconReactElement) || props.loading) && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            {props.icon && props.icon.left}
            {props.loading && <Spinner size="md" className="ml-2" />}
          </div>
        )}
        <input
          ref={ref}
          {...props}
          loading={props.loading?.toString()}
          clearable={props.clearable && props.clearable.toString()}
          onChange={handleChange}
          type={props.type || "text"}
          id={props.id}
          value={!props.loading && value}
          className={classes}
          placeholder={!props.loading ? props.placeholder : null}
          onBlur={handleBlur}
          autoComplete={props.autoComplete}
        />
        {props.icon && (isIconReactElement || props.icon?.right) && (
          <div className={iconClasses}>
            {!isIconReactElement && props.icon?.right}
            {isIconReactElement && props.icon}
          </div>
        )}
        {props.clearable && (
          <button
            type="button"
            className="flex absolute inset-y-0 right-0 items-center pr-3"
            onClick={clearValue}
          >
            <XMarkIcon className="w-4 h-4" />
          </button>
        )}
      </div>
      {props.footer ? (
        props.footer
      ) : props.footerButton ? (
        <div className="flex justify-end">
          <Button type="link" onClick={props.footerButtonClick}>
            {props.footerButton}
          </Button>
        </div>
      ) : (
        props.info && (
          <p
            id="helper-text-explanation"
            className="mt-2 text-sm text-gray-500"
          >
            {props.info}
          </p>
        )
      )}

      {props.error && showError && (
        <p className="mt-2 text-sm text-red-600">{props.error}</p>
      )}
    </div>
  );
});

export default Input;
