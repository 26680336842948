import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useAuth } from "./useAuth";
import { isObject } from "../utils";
import { toast } from "react-toastify";
import { APP_TYPE, PARENT_COMPANY } from "../config/defaultSettings";
import { useFetchConfigMutation } from "../redux/services/config/configApi";

const contextDefaultValues = {
  configs: { parentCompany: {} },
  addConfig: () => Promise.resolve(),
  deleteConfig: () => Promise.resolve(),
  appType: "",
  parentCompany: "",
};

export const ConfigContext = createContext(contextDefaultValues);

export const ConfigProvider = ({ children }) => {
  const [configs, setConfigs] = useState({});
  const { user, initialized, isAuthenticated } = useAuth();
  const [configInitialized, setConfigInitialized] = useState(false);
  const parentCompany = localStorage.getItem(PARENT_COMPANY);
  const appType = localStorage.getItem(APP_TYPE);

  const [fetchConfig, { isLoading: getConfigLoading }] =
    useFetchConfigMutation();

  useEffect(() => {
    const fetchConfigs = async () => {
      if (!configInitialized && user?.active && parentCompany) {
        try {
          let configData = await fetchConfig({ parentCompany }).unwrap();
          const { config } = configData;

          console.log("config", config);

          localStorage.setItem(APP_TYPE, config.configs.parentCompany.appType);
          setConfigs(config ? config.configs : {});
        } catch (err) {
          console.error("Failed to fetch configurations:", err);
          toast.error(
            "Failed to fetch configurations. Please try again later."
          );
        } finally {
          setConfigInitialized(true);
        }
      }
    };

    console.log("initialized", initialized);

    if (initialized && isAuthenticated) {
      fetchConfigs();
    } else {
      cleanUpConfig();
    }
  }, [
    initialized,
    configInitialized,
    user?.active,
    parentCompany,
    isAuthenticated,
    fetchConfig,
  ]);

  const addConfig = useCallback(
    async (newConfigItem) => {
      if (!parentCompany) return;

      try {
        const response = await fetch(`/config/${parentCompany}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newConfigItem),
          credentials: "include",
        });

        const data = await response.json();

        if (data.status === "success") {
          setConfigs(data.config.configs);
          toast.success(data.message);
        }
      } catch (error) {
        console.error("Failed to add config:", error);
        toast.error("Failed to add configuration");
      }
    },
    [parentCompany]
  );

  const deleteConfig = useCallback(
    async (configData) => {
      if (!parentCompany) return;

      try {
        const response = await fetch(`/config/${parentCompany}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(configData),
          credentials: "include",
        });

        const data = await response.json();

        if (data.status === "success") {
          setConfigs(data.config.configs);
          toast.success(data.message);
        }
      } catch (error) {
        console.error("Failed to delete config:", error);
        toast.error("Failed to delete configuration");
      }
    },
    [parentCompany]
  );

  const cleanUpConfig = useCallback(() => {
    setConfigs({});
    setConfigInitialized(false);
    localStorage.removeItem(APP_TYPE);
  }, []);

  const contextValue = useMemo(
    () => ({
      configs: configs || {},
      addConfig,
      deleteConfig,
      parentCompany,
      appType: configs.parentCompany?.appType || appType,
      loading: !configInitialized || getConfigLoading,
      cleanUpConfig,
    }),
    [
      configs,
      addConfig,
      deleteConfig,
      parentCompany,
      configInitialized,
      getConfigLoading,
      cleanUpConfig,
    ]
  );

  return (
    <ConfigContext.Provider value={contextValue}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};

export default ConfigProvider;
