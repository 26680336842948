import { Link } from "react-router-dom";
import { renderCustomColumnDefs } from "../../../../../shared/Table/table.utils";
import {
  formatDate,
  renderAmount,
  renderTotalMileage,
} from "../../../../../utils";

// Config for table and form
const tableConfig = [
  // {
  //   Header: "#",
  //   accessor: "",
  //   Cell: (row) => {
  //     return <div>{row.row.id * 1 + 1}</div>;
  //   },
  //   disableSortBy: true,
  //   disableFilters: true,
  // },
  {
    id: "jobId",
    visible: true,
    required: true,
    minSize: 180,
    // editable: true,
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/jobs/${original._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  {
    id: "appointmentDate",
    header: "Appointment Date",
    minSize: 170,
    cell: ({ getValue }) => {
      return formatDate(getValue(), null, true);
    },
  },

  {
    id: "performedBy",
    visible: false,
    cell: ({ getValue }) => {
      return getValue().firstName;
    },
    minSize: 50,
  },
  {
    header: "Parts Total",
    id: "totalPartsCharges",
    minSize: 150,
    cell: ({ getValue }) => {
      return renderAmount(getValue());
    },
  },
  {
    header: "Labor Total",
    id: "totalLaborCharges",
    minSize: 120,
    cell: ({ getValue }) => {
      return renderAmount(getValue());
    },
  },
  {
    header: "Service Fee",
    id: "serviceFee",
    minSize: 120,
    cell: ({ getValue }) => {
      return renderAmount(getValue());
    },
  },
  {
    header: "Total",
    id: "totalCharges",
    minSize: 120,
    cell: ({ getValue }) => {
      return renderAmount(getValue());
    },
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
