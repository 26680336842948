import React, { useState, useRef, useCallback, useEffect } from "react";
import { SidebarContainer } from "../../../components";
import {
  combineCSVFiles,
  exportToJSON,
  exportToCSV,
  formatClientRecords,
} from "../../../utils/csvCombiner";
import { Spinner } from "../../../shared";
import { toast } from "react-toastify";
import { ROOT_API_URL } from "../../../config";
import request from "../../../utils/request";
import keyMaps from "../../../utils/data-upload/key-maps";
import { formatServiceRecords } from "../../../utils/data-upload/formatters";

/**
 * CSVHandler component for combining and processing CSV files
 * @returns {JSX.Element} CSV handling interface
 */
const CSVHandler = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [summary, setSummary] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedKeyMap, setSelectedKeyMap] = useState(null);
  const fileInputRef = useRef(null);
  const [formattedRecords, setFormattedRecords] = useState(null);

  /**
   * Analyzes CSV files and extracts headers
   * @param {FileList} files - Uploaded files
   */
  const analyzeFiles = useCallback(async (files) => {
    setIsProcessing(true);
    try {
      // Read the first row of each file to get headers
      const fileHeaders = await Promise.all(
        Array.from(files).map(async (file) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              const text = e.target.result;
              const firstLine = text.split("\n")[0];
              const headers = firstLine.split(",").map((h) => h.trim());
              resolve(headers);
            };
            reader.readAsText(file);
          });
        })
      );

      // Combine and deduplicate headers
      const uniqueHeaders = [...new Set(fileHeaders.flat())];
      setHeaders(uniqueHeaders);
      setSelectedHeaders(uniqueHeaders); // Initially select all headers
      setFiles(Array.from(files));
    } catch (error) {
      console.error("Error analyzing files:", error);
      toast.error("Error analyzing files. Please check console for details.");
    } finally {
      setIsProcessing(false);
    }
  }, []);

  /**
   * Handles file selection
   * @param {Event} event - Upload event containing files
   */
  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;
    await analyzeFiles(files);
  };

  /**
   * Handles export with selected headers
   * @param {string} exportFormat - 'csv' or 'json'
   */
  const handleExport = async (exportFormat) => {
    if (!files.length || !selectedHeaders.length) {
      toast.error("Please select files and at least one header");
      return;
    }

    setIsProcessing(true);
    try {
      const combined = await combineCSVFiles(files, selectedHeaders);
      setSummary(combined.summary);

      if (exportFormat === "csv") {
        exportToCSV(combined, "combined-csv-data");
      } else {
        exportToJSON(combined, "combined-csv-data");
      }

      toast.success("Files processed successfully!");
    } catch (error) {
      console.error("Error processing files:", error);
      toast.error("Error processing files. Please check console for details.");
    } finally {
      setIsProcessing(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  /**
   * Handles service record formatting and display
   */
  const handleFormatRecords = async () => {
    if (!files.length || !selectedHeaders.length) {
      toast.error("Please select files and at least one header");
      return;
    }

    setIsProcessing(true);
    try {
      const serviceRecords = await formatServiceRecords(files, {
        selectedHeaders: selectedHeaders,
        keyMappings: keyMaps[selectedKeyMap],
      });

      // Check if we have valid data
      if (!serviceRecords || Object.keys(serviceRecords).length === 0) {
        throw new Error("No records were formatted");
      }

      setFormattedRecords(serviceRecords);
      toast.success("Service records formatted successfully!");
    } catch (error) {
      console.error("Error formatting service records:", error);
      toast.error(error.message || "Error formatting records");
    } finally {
      setIsProcessing(false);
    }
  };

  /**
   * Handles client record formatting and display
   */
  const handleFormatClientRecords = async () => {
    if (!files.length || !selectedHeaders.length) {
      toast.error("Please select files and at least one header");
      return;
    }

    setIsProcessing(true);
    try {
      // Assuming you have a utility function for formatting client records
      const clientRecords = await formatClientRecords(files, {
        selectedHeaders: selectedHeaders,
      });

      if (!clientRecords || Object.keys(clientRecords).length === 0) {
        throw new Error("No client records were formatted");
      }

      setFormattedRecords(clientRecords);
      toast.success("Client records formatted successfully!");
    } catch (error) {
      console.error("Error formatting client records:", error);
      toast.error(error.message || "Error formatting client records");
    } finally {
      setIsProcessing(false);
    }
  };

  /**
   * Handles downloading formatted records
   * @param {string} format - 'csv' or 'json'
   */
  const handleDownloadFormatted = (format) => {
    if (!formattedRecords) {
      toast.error("No formatted records to download");
      return;
    }

    try {
      // Ensure we have data in the correct format for export
      const dataToExport = {
        data: Array.isArray(formattedRecords)
          ? formattedRecords
          : Object.values(formattedRecords),
      };

      if (format === "csv") {
        exportToCSV(dataToExport, "formatted-service-records");
      } else {
        exportToJSON(dataToExport, "formatted-service-records");
      }
      toast.success(`Downloaded as ${format.toUpperCase()}`);
    } catch (error) {
      console.error(`Error downloading as ${format}:`, error);
      toast.error(`Error downloading as ${format}`);
    }
  };

  const title = (
    <div className="text-gray-800 font-semibold text-lg">CSV File Handler</div>
  );

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderHeader = (headerProps) => {
    return (
      <div ref={headerContext}>
        <header className="flex items-center justify-between px-4 py-3 bg-white border-b border-gray-200">
          <div className="text-xl font-semibold text-gray-800">
            {headerProps.title}
          </div>
        </header>
      </div>
    );
  };

  const content = (
    <div className="flex flex-col items-center justify-center p-6 bg-gray-50">
      <div className="w-full max-w-2xl bg-white rounded-lg shadow-sm p-6 space-y-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Upload CSV Files
        </h2>

        <div className="space-y-4">
          <input
            ref={fileInputRef}
            type="file"
            multiple
            accept=".csv"
            className="hidden"
            onChange={handleFileSelect}
          />

          <button
            onClick={() => fileInputRef.current?.click()}
            disabled={isProcessing}
            className="w-full px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
          >
            Select CSV Files
          </button>

          <div className="w-full">
            <label
              htmlFor="keyMap"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Select Key Mapping
            </label>
            <select
              id="keyMap"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
              onChange={(e) => setSelectedKeyMap(e.target.value)}
              value={selectedKeyMap}
            >
              <option value="">Select a key mapping...</option>
              {Object.keys(keyMaps).map((keyMap) => (
                <option key={keyMap} value={keyMap}>
                  {keyMap}
                </option>
              ))}
            </select>
          </div>

          {headers && (
            <div className="mt-6">
              <h3 className="text-lg font-semibold text-gray-700 mb-3">
                Select Columns to Include
              </h3>
              <div className="max-h-60 overflow-y-auto p-4 border border-gray-200 rounded-md">
                {headers.map((header) => (
                  <div key={header} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      id={header}
                      checked={selectedHeaders.includes(header)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedHeaders([...selectedHeaders, header]);
                        } else {
                          setSelectedHeaders(
                            selectedHeaders.filter((h) => h !== header)
                          );
                        }
                      }}
                      className="mr-2"
                    />
                    <label htmlFor={header} className="text-gray-700">
                      {header}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}

          {headers && (
            <div className="flex gap-4">
              <button
                onClick={() => handleExport("csv")}
                disabled={isProcessing || !selectedHeaders.length}
                className="flex-1 px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
              >
                Export as CSV
              </button>
              <button
                onClick={() => handleExport("json")}
                disabled={isProcessing || !selectedHeaders.length}
                className="flex-1 px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
              >
                Export as JSON
              </button>
            </div>
          )}

          {headers && (
            <div className="flex gap-4">
              <button
                onClick={handleFormatRecords}
                disabled={isProcessing || !selectedHeaders.length}
                className="flex-1 px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
              >
                Format Service Records
              </button>
              <button
                onClick={handleFormatClientRecords}
                disabled={isProcessing || !selectedHeaders.length}
                className="flex-1 px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
              >
                Format Client Records
              </button>
            </div>
          )}

          {formattedRecords && (
            <div className="flex gap-4 mt-4">
              <button
                onClick={() => handleDownloadFormatted("csv")}
                className="flex-1 px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
              >
                Download Formatted CSV
              </button>
              <button
                onClick={() => handleDownloadFormatted("json")}
                className="flex-1 px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
              >
                Download Formatted JSON
              </button>
            </div>
          )}

          {formattedRecords && (
            <div className="mt-6 p-4 bg-white rounded-md border border-gray-200">
              <h3 className="font-semibold text-gray-700 mb-2">
                Formatted Service Records
              </h3>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      {selectedHeaders.map((header) => (
                        <th
                          key={header}
                          className="px-4 py-2 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {Array.isArray(formattedRecords)
                      ? formattedRecords.slice(0, 10).map((record, index) => (
                          <tr key={index}>
                            {selectedHeaders.map((header) => (
                              <td
                                key={header}
                                className="px-4 py-2 whitespace-nowrap text-sm text-gray-500"
                              >
                                {record[header] || ""}
                              </td>
                            ))}
                          </tr>
                        ))
                      : Object.values(formattedRecords)
                          .slice(0, 10)
                          .map((record, index) => (
                            <tr key={index}>
                              {selectedHeaders.map((header) => (
                                <td
                                  key={header}
                                  className="px-4 py-2 whitespace-nowrap text-sm text-gray-500"
                                >
                                  {record[header] || ""}
                                </td>
                              ))}
                            </tr>
                          ))}
                  </tbody>
                </table>
                <p className="mt-2 text-sm text-gray-500">
                  Showing first 10 records of{" "}
                  {Array.isArray(formattedRecords)
                    ? formattedRecords.length
                    : Object.keys(formattedRecords).length}{" "}
                  total records
                </p>
              </div>
            </div>
          )}
        </div>

        {isProcessing && (
          <div className="flex items-center justify-center p-4 bg-gray-50 rounded-md">
            <Spinner size="md" />
            <p className="ml-3 text-gray-600">Processing files...</p>
          </div>
        )}

        {summary && (
          <div className="p-4 bg-green-50 rounded-md border border-green-200">
            <h3 className="font-semibold text-green-700 mb-2">
              Processing Complete!
            </h3>
            <div className="space-y-2 text-green-600">
              <p>Files Processed: {summary.filesProcessed}</p>
              <p>Total Rows: {summary.totalRows}</p>
              <p>Columns Included: {selectedHeaders.length}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <SidebarContainer title={title} header={renderHeader} hideSidebar>
      {({ parentHeight }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = {
          height: `${parentHeight - offsetTop - offsetHeight}px`,
        };

        return (
          <div
            className="px-3 pb-3 overflow-y-auto w-full"
            style={wrapperStyle}
          >
            {content}
          </div>
        );
      }}
    </SidebarContainer>
  );
};

export default CSVHandler;
