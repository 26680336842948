import React, { useState, useRef, useEffect } from "react";
import { Avatar, Button } from "../shared";
import { ChevronUpDownIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

/**
 * A multi-select dropdown component for assigning users
 * @param {Object} props Component props
 * @param {Array} props.value - Currently selected users
 * @param {Function} props.onChange - Callback when selection changes
 * @param {Array} props.users - List of available users to select from
 */
export const AssignedToSelect = ({
  value = [],
  onChange,
  users = [],
  list = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(value);
  const [currentData, setCurrentData] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setSelectedUsers(list ? [] : value);
  }, [value?.length]);

  // Handle clicking outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSave = () => {
    onChange(selectedUsers);
    setIsOpen(false);
  };

  const toggleUser = (user) => {
    setSelectedUsers((prev) => {
      const exists = prev.find((u) => u.id === user.id);
      if (exists) {
        return prev.filter((u) => u.id !== user.id);
      }
      return [...prev, user];
    });
  };

  const isSelected = (userId) => {
    return selectedUsers.some((user) => user.id === userId);
  };

  const handleClick = () => {
    setCurrentData(selectedUsers);
    setIsOpen(!isOpen);
  };

  const handleCancel = () => {
    setSelectedUsers(currentData);
    setIsOpen(false);
  };

  function renderList() {
    return (
      <div className="flex flex-row items-center gap-2">
        {renderTrigger()}
        <div className="flex flex-row items-center gap-2">
          {selectedUsers.map((item) => {
            return <UserCard {...item} />;
          })}
        </div>
      </div>
    );
  }

  function renderContent() {
    if (list) {
      return renderList();
    }
    return renderTrigger();
  }

  const triggerClass = classNames(
    "relative w-full flex items-center min-h-[40px] cursor-pointer rounded-lg",
    {
      "bg-white py-1 pl-1 pr-10 text-left border focus:outline-none focus:border-primary-500 focus:ring-2 focus:ring-white focus:ring-opacity-75 focus:ring-offset-2 focus:ring-offset-primary-300":
        !list,
    }
  );
  function renderTrigger() {
    return (
      <button onClick={handleClick} className={triggerClass}>
        <UserCardList list={list} selectedUsers={selectedUsers} />
        {!list && (
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        )}
      </button>
    );
  }

  return (
    <div className="relative w-full min-w-44 max-w-56" ref={dropdownRef}>
      {renderContent()}

      {isOpen && (
        <div className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20">
          {users.map((user) => {
            const active = isSelected(user._id);
            return (
              <div
                key={user._id}
                onClick={() => toggleUser({ ...user, id: user._id })}
                className={`relative cursor-pointer select-none py-2 pl-4 pr-4 ${
                  active ? "bg-primary-500 text-white" : "text-gray-900"
                } hover:bg-primary-500 hover:text-white`}
              >
                <div className="flex items-center space-x-2">
                  <Avatar name={user.user?.avatar} size="xs" />
                  <span className="text-sm">{user.user?.fullName}</span>
                  {active && (
                    <span
                      className={`absolute inset-y-0 right-0 flex items-center pr-3 ${
                        active ? "text-white" : "text-primary-500"
                      }`}
                    >
                      ✓
                    </span>
                  )}
                </div>
              </div>
            );
          })}
          <div className="flex justify-end space-x-2 p-2 pb-1 border-t">
            <Button type="alternative" size="xs" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" size="xs" onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const UserCard = ({ user: { avatar, fullName, firstName, lastName } }) => {
  const name = fullName || `${firstName} ${lastName}`;
  return (
    <div className="flex items-center overflow-hidden">
      <div className="flex -space-x-3 mr-2">
        <div className="w-8 h-8 rounded-full bg-gray-300 border-2 border-white flex items-center justify-center text-sm font-medium">
          {avatar ? <Avatar src={avatar} size="xs" name={name} /> : name[0]}
        </div>
      </div>
      <span className="block truncate text-gray-800">{name}</span>
    </div>
  );
};

const UserCardList = ({ selectedUsers, list }) => {
  return (
    <div className="flex items-center overflow-hidden">
      <div className="flex -space-x-3 mr-2">
        {!list && selectedUsers?.length ? (
          selectedUsers.map((v, i) => (
            <div
              key={i}
              className="w-8 h-8 rounded-full bg-gray-300 border-2 border-white flex items-center justify-center text-sm font-medium"
            >
              {v.user?.fullName[0]}
            </div>
          ))
        ) : (
          <PlusCircleIcon className="w-5 h-5 text-primary-500" />
        )}
      </div>
      <span className="block truncate text-gray-800">
        {list
          ? "Add Technician"
          : selectedUsers?.length
          ? selectedUsers.map((person) => person.user?.fullName).join(", ")
          : "Select Technicians"}
      </span>
    </div>
  );
};
