import React from "react";
import { useParams } from "react-router-dom";
import { useGetInventoryItemQuery } from "../../redux/services/inventory/inventoryApi";
import { toast } from "react-toastify";
import { Button } from "../../shared";

const InventoryShow = () => {
  const { id } = useParams();
  const {
    data: inventoryItem,
    isLoading,
    isError,
  } = useGetInventoryItemQuery(id);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading inventory item.</div>;

  const handleDelete = async () => {
    // Implement delete logic here
    toast.success("Item deleted successfully");
  };

  return (
    <div>
      <h1>Inventory Item Details</h1>
      <div>
        <h2>{inventoryItem.name}</h2>
        <p>Quantity: {inventoryItem.quantity}</p>
        <p>ID: {inventoryItem.id}</p>
        {/* Add more fields as necessary */}
      </div>
      <Button onClick={handleDelete} type="danger">
        Delete Item
      </Button>
    </div>
  );
};

export default InventoryShow;
