import { Link } from "react-router-dom";
import { renderCustomColumnDefs } from "../../../../shared/Table/table.utils";
import { statusOptions } from "../../../../constants/select";
import { formatDate, renderAmount } from "../../../../utils";

const tableConfig = [
  {
    id: "payoutId",
    visible: true,
    header: "Payout ID",
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/payouts/${original._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  {
    id: "dateCreated",
    cell: ({ getValue }) => formatDate(getValue(), null, true),
  },
  {
    id: "total",
    cell: ({ getValue }) => renderAmount(getValue()),
  },
  {
    id: "status",
    editable: {
      type: "select",
      options: statusOptions,
    },
    cell: ({ getValue }) => getValue(),
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
