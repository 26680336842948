import React, { useState } from "react";
import { Card, Button, DateRangePicker, Checkbox } from "../../../../shared";
// import {
//   // useGetDriverReportsQuery,
//   useGenerateDriverReportMutation,
// } from "../../../../redux/services/reports/reportsApi";

/**
 * DriverReports component displays and manages driver-specific reports
 * @param {Object} props - Component props
 * @param {Object} props.driver - Driver object containing driver information
 * @param {string} props.driverStatus - Current status of driver data loading
 */
const DriverReports = ({ driver, driverStatus }) => {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [includePayouts, setIncludePayouts] = useState(true);

  const { data: reports, isLoading } = useGenerateDriverReportMutation(
    driver._id
  );
  const [createReport, { isLoading: isCreating }] =
    useCreateDriverReportMutation();

  const handleCreateReport = async () => {
    const reportConfig = {
      driverId: driver._id,
      dateRange,
      includePayouts,
    };

    try {
      await createReport(reportConfig).unwrap();
    } catch (error) {
      console.error("Failed to create report:", error);
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {/* Report Creation Card */}
      <Card className="p-4">
        <h3 className="text-lg font-semibold mb-4">Create New Report</h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Date Range
            </label>
            <DateRangePicker
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              onChange={setDateRange}
            />
          </div>

          <div>
            <Checkbox
              label="Include Payouts"
              checked={includePayouts}
              onChange={(e) => setIncludePayouts(e.target.checked)}
            />
          </div>

          <Button
            onClick={handleCreateReport}
            loading={isCreating}
            disabled={!dateRange.startDate || !dateRange.endDate}
          >
            Generate Report
          </Button>
        </div>
      </Card>

      {/* Reports List Card */}
      <Card className="p-4">
        <h3 className="text-lg font-semibold mb-4">Previous Reports</h3>
        {isLoading ? (
          <div>Loading reports...</div>
        ) : (
          <div className="space-y-2">
            {reports?.map((report) => (
              <div
                key={report._id}
                className="p-3 border rounded hover:bg-gray-50 cursor-pointer"
              >
                <div className="flex justify-between items-center">
                  <span>{new Date(report.createdAt).toLocaleDateString()}</span>
                  <Button size="sm" variant="outline">
                    Download
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </Card>
    </div>
  );
};

export default DriverReports;
