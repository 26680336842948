import { useState } from "react";
import {
  FolderPlusIcon,
  PlusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import Button from "../../shared/Button";
import { SettingsPanel } from "../../shared";
import RateAddModal from "./RateAddModal";
import Input from "../../shared/Input";
import { camelCaseToSpaces, getAppType } from "../../utils";
import RateExistsModal from "./RateExistsModal";
import RateDeleteModal from "./RateDeleteModal";
import useForm from "../../hooks/useForm";
import { ratesMap } from "../../constants/select";

const RatesSettingsLayout = ({
  rates = {},
  title,
  subtitle,
  deleteRate,
  addRate,
  updateRate,
}) => {
  const formHook = useForm();
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [rateExistsModal, setRateExistsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");
  const [loading, setLoading] = useState(false);

  const rateKeys = Object.keys(rates);
  const ratesEmpty = Object.keys(rates).length > 0;
  const emptyData = [
    {
      content: (
        <div className="my-12">
          <div className="text-center">
            <FolderPlusIcon className="w-12 h-12 mx-auto text-gray-400 stroke-1" />
            <h3 className="mt-2 font-semibold text-sm">No Rates</h3>
            <p className="text-sm text-gray-500">
              Get started by adding a rate
            </p>
            <div className="mt-3">{renderButton("md")}</div>
          </div>
        </div>
      ),
    },
  ];

  const rateData = rateKeys.map((key) => {
    const rate = rates[key];
    const rateDesc = ratesMap[getAppType()].find((o) => o.id === key);

    return {
      title: camelCaseToSpaces(key),
      subtitle: rateDesc?.description,
      content: (
        <div className="flex items-center space-x-1">
          <div className="w-full">
            <Input
              id={key}
              onChange={formHook.updateForm}
              value={formHook.form[key] || rate}
              size="md"
              type="number"
              onBlur={() => handleUpdate(key)}
            />
          </div>
          <div onClick={() => handleDeleteModal(key)}>
            <XCircleIcon className="text-red-500 w-6 h-6 cursor-pointer" />
          </div>
        </div>
      ),
    };
  });

  function renderButton(size) {
    const wh = size === "md" ? 5 : 4;
    return (
      <Button
        onClick={() => setAddModalVisible(true)}
        size={size || "xs"}
        icon={<PlusCircleIcon className={`w-${wh} h-${wh} mr-2`} />}
      >
        Add Rate
      </Button>
    );
  }

  function handleDeleteModal(key) {
    setDeleteModal(true);
    setItemToDelete(key);
  }

  function handleDelete() {
    const data = { type: itemToDelete, configType: "rates" };

    deleteRate(data);
    closeDeleteModal();
  }

  function closeDeleteModal() {
    setLoading(false);
    setDeleteModal(false);
    setItemToDelete("");
  }

  function handleUpdate(key) {
    handleAddRate(key, "update");
  }

  // Check if rate already exists
  function handleClickAddRate() {
    const rateType = formHook.form.data.id;

    setLoading(true);
    if (rateType in rates) {
      setAddModalVisible(false);
      setRateExistsModal(true);
    } else {
      handleAddRate();
    }
    setLoading(false);
  }

  // Save rate
  function handleAddRate(key, saveType) {
    setLoading(true);

    const rateData = {
      data: {
        [key || formHook.form.data.id]: key
          ? Number(formHook.form[key])
          : Number(formHook.form.total),
      },
      configKey: "rates",
      type: "object",
    };

    saveType === "update"
      ? updateRate(rateData, "update")
      : addRate(rateData, "add");

    setAddModalVisible(false);
    setRateExistsModal(false);
    formHook.clearForm();
    setLoading(false);
  }

  const data = !ratesEmpty ? emptyData : rateData;

  return (
    <>
      <div className="space-y-4">
        <SettingsPanel
          title={title}
          subtitle={subtitle}
          actions={renderButton()}
          data={data}
        />
      </div>
      <RateAddModal
        form={formHook}
        visible={addModalVisible}
        setVisible={setAddModalVisible}
        submit={handleClickAddRate}
        loading={loading}
      />
      <RateExistsModal
        visible={rateExistsModal}
        setVisible={setRateExistsModal}
        submit={handleUpdate}
        loading={loading}
      />
      <RateDeleteModal
        visible={deleteModal}
        setVisible={setDeleteModal}
        submit={handleDelete}
        loading={loading}
      />
    </>
  );
};

export default RatesSettingsLayout;
