import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { COLUMN_DEFINITIONS } from "./table.config";
import { Button, Table } from "../../../../shared";
import Expanded from "./Expanded";
import _ from "lodash";
import { renderCustomColumnDefs } from "../../../../shared/Table/table.utils";
import { useAuth } from "../../../../hooks";
import { FolderPlusIcon } from "@heroicons/react/24/outline";

export const MaintenanceTable = ({
  equipment,
  maintenanceHistory,
  tableConfig,
  form = {},
  visible = false,
  setVisible,
  ...props
}) => {
  const tableRef = useRef(null);
  const { user } = useAuth();
  const [addVisible, setAddVisible] = useState(false);
  const [removeVisible, setRemoveVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const newTableData = [
    // {
    //   id: "status",
    //   size: 70,
    //   cell: ({ getValue }) => (
    //     <Badge className="!px-1" color="blue">
    //       {getValue()}
    //     </Badge>
    //   ),
    // },
    // {
    //   id: "orderNumber",
    //   required: true,
    //   cell: ({ row, getValue, table }) => {
    //     const value = getValue();
    //     const data = row.original;
    //     return (
    //       <div>
    //         {!isAuthorized("owner, admin") ? (
    //           value
    //         ) : (
    //           <div
    //             className="text-primary-500 hover:underline cursor-pointer"
    //             onClick={() => {
    //               row.toggleExpanded();
    //               handleEditLeg(row.original, table.options.meta.job);
    //             }}
    //           >
    //             {value}
    //           </div>
    //         )}
    //         {(data.isHandoff || data.hasHandoff) && (
    //           <Badge>
    //             {data.isHandoff && "Handoff"}
    //             {data.hasHandoff && "Has Handoff"}
    //           </Badge>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    ...COLUMN_DEFINITIONS,
  ];

  const NEW_COLUMN_DEFINITIONS = renderCustomColumnDefs(newTableData);

  function handleEditLeg(e, jobData) {
    const updateItem = {
      ...e,
      client: jobData?.client,
      origin: e.route?.origin,
      destination: e.route?.destination,
      totalMiles: e.route?.mileage,
      customMileage: e.route?.customMileage,
      useCustomMileage: !!e.route?.customMileage,
      appointmentDate: e.appointmentDate,
      appointmentTime: e.appointment?.time,
      totalWaitTime: e.waitTime?.total,
      waitStart: e.waitTime?.start,
      waitEnd: e.waitTime?.end,
      base: jobData.base || jobData.baseRate,
      fuelRate: jobData.fuelRate,
      orderNumber: e.orderNumber,
      useLegFlatRate: jobData.useFlatRate || e.useLegFlatRate,
      job: jobData,
      edit: true,
    };
    form.setFormInit(updateItem, { setErrors: true });
    setVisible(true);
  }

  function handleAddAccessoryToLeg(e) {
    const updateItem = {
      ...e,
      client: job?.client,
      edit: true,
    };
    form.setFormInit(updateItem, { setErrors: true });
    setAddVisible(true);
  }

  function onModalCancel() {
    form.clearForm();
    setVisible(false);
  }

  function resetExpandedRows() {
    tableRef.current.resetExpandedRows(false);
  }

  return (
    <>
      <Table
        id="maintenance"
        ref={tableRef}
        data={maintenanceHistory}
        columns={tableConfig}
        hideCheckbox
        hideHeader
        // rowClickExpand
        // getRowCanExpand={() => true}
        loading={loading}
        pageSize={100}
        // pagination
        globalSearchFilters={["transactionDate", "loadNumber", "orderNumber"]}
        emptyState={
          <div className="py-10 h-full ">
            <div className="text-center">
              <FolderPlusIcon className="w-12 h-12 mx-auto text-gray-400 stroke-1" />
              <h3 className="mt-2 font-semibold text-sm">
                No Maintenance Records
              </h3>
              <p className="text-sm text-gray-500 mb-4">
                Get started by adding maintenance records
              </p>
              <Button size="xs" onClick={() => setAddVisible(true)}>
                Add Maintenance
              </Button>
            </div>
          </div>
        }
        // meta={{
        //   data,
        // }}
        // expandedLayout={(e) => (
        //   <Expanded
        //     {...e}
        //     {...props}
        //     data={maintenanceHistory}
        //     loading={loading}
        //     setLoading={setLoading}
        //     addAccessory={handleAddAccessoryToLeg}
        //     editLeg={handleEditLeg}
        //     resetExpandedRows={resetExpandedRows}
        //     // deleteLeg={handleDeleteLegs}
        //   />
        // )}
        {...props}
      />
    </>
  );
};

export default MaintenanceTable;
