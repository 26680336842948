import { useEffect, useState } from "react";
import { Button, Input } from "../../shared";
import { UserOnboarding } from "./User";
import { renderValidations } from "../../shared/Table/table.utils";
import validations from "./validations";
import useForm from "../../hooks/useForm";
import { CustomerOnboarding } from "./Customer";
import { useAuth, useQueryNavigation } from "../../hooks";
import { useNavigate } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import {
  activateAccount,
  updatedUser,
} from "../../redux/reducers/accountSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import request from "../../utils/request";
import { setToken } from "../../utils/authority";
import { PARENT_COMPANY } from "../../config";

const path = [
  { id: "namePassword", sort: 1, content: UserOnboarding },
  { id: "customer", sort: 2, content: CustomerOnboarding },
];
// activate?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1N2E4ODdhMmI1ZTU2NDUxODc4MjNlOSIsImVtYWlsIjoic2NvdHQuYXNoZXIzM0BnbWFpbC5jb20iLCJuYW1lIjoidW5kZWZpbmVkIHVuZGVmaW5lZCIsImF1dGhvcml0eSI6ImFkbWluIiwicGFyZW50Q29tcGFueSI6IjY1N2E4ODc5MmI1ZTU2NDUxODc4MjNlNCIsImlhdCI6MTcwMjUyOTE0NiwiZXhwIjoxNzAyNjE1NTQ2fQ.5P5jUzU_6VqNNS4Ip_iN6Zx-djQYXeTZ8pQP3ipdU9Y
export const Onboarding = (props) => {
  const { setParam, getParam } = useQueryNavigation("onboarding");
  const { setUser } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queries = useQuery();
  const token = queries.get("token");
  const userId = queries.get("userId");
  const customer = queries.get("customer");
  const [currentUser, setCurrentUser] = useState({});
  const [companyData, setCompanyData] = useState({});

  function getCurrenPath() {
    return path.find((i) => i.id === getParam("onboarding"));
  }
  function getNextPath(currentId) {
    return path.find((i) => i.sort === currentId + 1);
  }
  function getPreviousPath(currentId) {
    return path.find((i) => i.sort === currentId - 1);
  }
  const currentPath = getCurrenPath() || path[0];

  useEffect(() => {
    setParam({ onboarding: currentPath.id || path[0].id, token });
  }, []);

  const form = useForm({
    validations: renderValidations(validations[currentPath.id]),
  });

  async function handleActivateAccount() {
    const formData = {
      ...form.form,
    };
    const activated = await dispatch(
      activateAccount({ token, data: formData })
    ).unwrap();

    setCurrentUser(activated.user);
    const data = {
      ...activated.user.parentCompany,
      parentCompanyId: activated.user.parentCompany._id,
    };

    form.setFormInit(data, { reset: true });

    toast(activated.message, {
      type: activated.status,
      toastId: "userActivated",
    });

    return activated.user;
  }

  async function handleActivateCustomer() {
    const formData = {
      name: form.form.name,
      phone: form.form.phone,
      slug: form.form.slug,
      address: {
        address: form.form.address,
        city: form.form.city,
        state: form.form.state,
        zipcode: form.form.zipcode,
        suite: form.form.suite,
        country: form.form.country,
      },
    };

    const activated = await request(
      "/customers/" + form.form.parentCompanyId + "/activate",
      {
        method: "put",
        data: formData,
      }
    );

    setUser(currentUser);
    toast(activated.message, {
      type: activated.status,
      toastId: "userActivated",
    });
    dispatch(updatedUser(currentUser));

    return activated;
  }

  async function handleSubmit(userSignUp) {
    if (currentPath.id === "namePassword") {
      const user = await handleActivateAccount();
      if (user._id) {
        // setUser(user);
        setToken(user.apiTok);
        localStorage.setItem(PARENT_COMPANY, user.parentCompany._id);
        if (!user.accountHolder) {
          return navigate("/");
        }
      }

      return user;
    }

    if (currentPath.id === "customer") {
      const customer = await handleActivateCustomer();

      return { id: "customer", customer };
    }
  }

  async function handleNext() {
    const next = getNextPath(currentPath.sort);
    const submit = await handleSubmit(!customer);

    if (!next || !customer) {
      navigate("/");
    } else {
      setParam({ onboarding: next.id });
    }
  }

  function handleBack() {
    const before = getPreviousPath(currentPath.sort);
    if (before) {
      setParam({ onboarding: before.id });
    }
  }

  function handleForm(e) {
    const { value, id } = e;
    form.setFormInit({ ...form.form, [id]: value }, { setErrors: true });
  }

  const Content = currentPath.content;

  return (
    <div className="flex flex-col items-center justify-center md:justify-start my-auto pt-8 md:pt-0 px-1 md:px-5 lg:px-24">
      <div className="flex flex-col justify-center items-center md:w-full">
        <div className="text-3xl font-bold">Welcome, to Attune Logic.</div>
        <div className="text-gray-500">
          We need a bit more information before you get started
        </div>
        <Content
          {...form}
          handleForm={handleForm}
          currentUser={currentUser}
          companyData={companyData}
        />
      </div>
      <div className="flex flex-col justify-center items-center">
        <div className="pt-10 w-full flex justify-end gap-2">
          <Button
            type="alternative"
            disabled={currentPath.sort === 1}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button disabled={!form.isValid} onClick={handleNext}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};
