import { getSearchParams } from "../../../utils/request";
import { capitalizeFirstLetter } from "../../../utils";
import { api } from "../../api";

const mediaApis = api.injectEndpoints({
  tagTypes: ["Media"],
  endpoints: (builder) => ({
    getMedia: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const query = getSearchParams({
          legacy: false,
          page,
          limit,
          ...rest,
        });
        return `media?${query}`;
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result) => {
        return result
          ? [
              ...result.map(({ id }) => ({ type: "Media", id })),
              { type: "Media", id: "LIST" },
            ]
          : [{ type: "Media", id: "LIST" }];
      },
    }),
    addMedia: builder.mutation({
      query({ formData }) {
        return {
          formData: true,
          url: "media",
          method: "POST",
          body: formData,
        };
      },
      transformResponse: (response, meta, arg) => {
        return response;
      },
      invalidatesTags: (result, _error, args) => {
        return [
          { type: "Jobs", id: "LIST" },
          { type: "Media", id: "LIST" },
        ];
      },
    }),
    getMediaObject: builder.query({
      query: ({ id, ...rest }) => `media/${id}?${getSearchParams(rest)}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, id) => [{ type: "Media", id }],
    }),
    updateMedia: builder.mutation({
      query({ id, data }) {
        return {
          url: `/media/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Media", id }],
    }),
    deleteMedia: builder.mutation({
      query({ data }) {
        return {
          url: `/media`,
          method: "DELETE",
          body: data,
        };
      },
      invalidatesTags: (result, error, { data }) =>
        data.map((id) => ({ type: "Media", id })),
    }),
    removeMedia: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/media/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Media", id }],
    }),
    replaceMedia: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/media/${id}/replace`,
          method: "PUT",
          body: data,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMediaQuery,
  useGetMediaObjectQuery,
  useAddMediaMutation,
  useUpdateMediaMutation,
  useDeleteMediaMutation,
  useRemoveMediaMutation,
  useReplaceMediaMutation,
} = mediaApis;
