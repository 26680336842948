import { getSearchParams } from "../../../utils/request";
import { api } from "../../api";

const payoutsApi = api.injectEndpoints({
  tagTypes: ["Payouts"],
  endpoints: (builder) => ({
    getPayouts: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const params = new URLSearchParams({
          page,
          limit,
          legacy: false,
          ...rest,
        });
        return `payouts?${params}`;
      },
      providesTags: (data) =>
        data?.payouts
          ? [
              ...data.payouts.map(({ _id }) => ({ type: "Payouts", id: _id })),
              { type: "Payouts", id: "LIST" },
            ]
          : [{ type: "Payouts", id: "LIST" }],
    }),
    addPayout: builder.mutation({
      query(body) {
        return {
          url: "payouts",
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, _error, data) => {
        return [
          ...result.data.legs.map(({ jobId }) => ({
            type: "Jobs",
            id: jobId._id,
          })),
          {
            type: "Manifests",
            id: data.manifestId,
          },
          { type: "Payouts", id: result.data._id },
        ];
      },
    }),
    getPayout: builder.query({
      query: ({ id, ...rest }) => `payouts/${id}?${getSearchParams(rest)}`,
      transformResponse: (response) => response.data,
      providesTags: (_result, _error, data) => {
        return [{ type: "Payouts", id: data.id }];
      },
    }),
    updatePayout: builder.mutation({
      query({ id, data, legacy = false }) {
        return {
          url: `/payouts/${id}${legacy ? "/legacy" : ""}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, _error, data) => {
        const jobs = result.data.legs
          .filter((i) => i.jobId)
          .map((item) => ({
            type: "Jobs",
            id: item.jobId?._id,
          }));

        return [
          ...jobs,
          { type: "Legs", id: "LIST" },
          { type: "Payouts", id: data.id },
        ];
      },
    }),
    updatePayoutLeg: builder.mutation({
      query({ data, id, legId, ...obj }) {
        const params = getSearchParams(obj);
        return {
          url: `/payouts/${id}/leg/${legId}?${params}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, _error, data) => {
        const jobs = result.data.legs
          .filter((i) => i.jobId)
          .map((item) => ({
            type: "Jobs",
            id: item.jobId?._id,
          }));

        return [
          ...jobs,
          { type: "Legs", id: "LIST" },
          { type: "Payouts", id: data.id },
        ];
      },
    }),
    deletePayouts: builder.mutation({
      query({ data }) {
        return {
          url: `/payouts`,
          method: "DELETE",
          body: data,
        };
      },
      invalidatesTags: (result, _error, { data }) => [
        ...data.map((id) => ({ type: "Payouts", id })),
        { type: "Manifests", id: "LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPayoutsQuery,
  useAddPayoutMutation,
  useGetPayoutQuery,
  useLazyGetPayoutQuery,
  useUpdatePayoutMutation,
  useUpdatePayoutLegMutation,
  useDeletePayoutsMutation,
} = payoutsApi;
