import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Table from "../../../../shared/Table";
import { useAuth } from "../../../../hooks/useAuth";
import { updateJob } from "../../../../redux/reducers/jobs/jobSlice";
import {
  fetchLegs,
  selectAllLegs,
} from "../../../../redux/reducers/legs/legsSlice";
import {
  fetchJobs,
  selectAllJobs,
  resetJobs,
  jobUpdated,
} from "../../../../redux/reducers/jobs/jobsSlice";
import { COLUMN_DEFINITIONS } from "./table.config";
import { useConfig } from "../../../../hooks";

const EmployeeJobs = ({ employee }) => {
  const { user } = useAuth();
  const params = useParams();
  const dispatch = useDispatch();
  const legs = useSelector(selectAllLegs);
  const jobs = useSelector(selectAllJobs);
  const jobsStatus = useSelector((state) => state.legs.status);
  const fullJobsStatus = useSelector((state) => state.jobs.status);
  const { config } = useConfig();
  const appType = config?.appType;

  const isTrucking = user.configs?.parentCompany?.appType === "trucking";
  const tableData = isTrucking ? legs : jobs;
  const loadingStatus = isTrucking ? jobsStatus : fullJobsStatus;

  useEffect(() => {
    if (user._id && params.id && loadingStatus === "idle") {
      if (isTrucking) {
        dispatch(
          fetchLegs({
            parent: user.parentCompany,
            userId: user._id,
            employeeId: params.id,
          })
        );
      } else {
        dispatch(
          fetchJobs({
            parent: user.parentCompany,
            userId: user._id,
            employeeId: params.id,
          })
        );
      }
    }
  }, [loadingStatus, dispatch, user, employee, isTrucking]);

  useEffect(() => {
    return () => dispatch(resetJobs());
  }, [dispatch]);

  async function handleUpdate(e, setLoading) {
    const updateData = {
      parentId: user.parentCompany,
      jobId: e.row.original._id,
      data: e.data,
    };

    const res = await dispatch(updateJob(updateData)).unwrap();

    if (res.status === "success") {
      dispatch(jobUpdated(res.data));
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }

  return (
    <div className="col-span-8">
      <Table
        id="jobs"
        search
        title="Jobs"
        loading={loadingStatus === "loading"}
        columns={COLUMN_DEFINITIONS}
        data={tableData}
        pagination
        pageSize={8}
        hideFooter
        updateData={handleUpdate}
      />
    </div>
  );
};

export default EmployeeJobs;
