import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  PARENT_COMPANY,
  ROOT_API_URL,
  USER,
  TOKEN_NAME,
  REFRESH_TOKEN_NAME,
} from "../config/defaultSettings";
import { getAppType } from "../utils";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const clearAuthData = async () => {
  try {
    const logoutResponse = await fetch(`${ROOT_API_URL}/account/logout`, {
      method: "POST",
      credentials: "include",
    });
  } catch (error) {
    console.error("Error during logout:", error);
  }

  // localStorage.removeItem(USER);
  // localStorage.removeItem(PARENT_COMPANY);

  // document.cookie = `${TOKEN_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  // document.cookie = `${REFRESH_TOKEN_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;

  window.location.replace("/login");
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: ROOT_API_URL,
    credentials: "include",
    prepareHeaders: (headers) => {
      headers.set("x-client-type", "web");
      headers.set("x-app-type", getAppType());
      const parentCompany = localStorage.getItem(PARENT_COMPANY);
      headers.set("x-parent-company", parentCompany);

      return headers;
    },
  });

  let result = await baseQuery(args, api, extraOptions);

  // Version check
  if (result.meta?.response?.headers) {
    try {
      const serverVersion = result.meta.response.headers.get("x-version");
      const currentVersion = import.meta.env.PACKAGE_VERSION;

      if (serverVersion && serverVersion > currentVersion) {
        window.localStorage.setItem("version-update-needed", "true");
      }
      window.localStorage.setItem("version", currentVersion);
    } catch (err) {
      console.error("Version Check Error:", err);
    }
  }

  if (result.error?.status === 401 || result.error?.status === 403) {
    if (!isRefreshing) {
      isRefreshing = true;

      try {
        const refreshResult = await baseQuery(
          {
            url: "/account/refresh",
            method: "POST",
            credentials: "include",
          },
          api,
          extraOptions
        );

        if (refreshResult.data?.success) {
          processQueue(null, refreshResult.data);
          result = await baseQuery(args, api, extraOptions);
        } else {
          processQueue(new Error("Refresh failed"));
          await clearAuthData();
        }
      } catch (error) {
        console.error("Refresh error:", error);
        processQueue(error);
        await clearAuthData();
      } finally {
        isRefreshing = false;
      }
    } else {
      try {
        await new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        });
        result = await baseQuery(args, api, extraOptions);
      } catch (err) {
        console.error("Queue error:", err);
        await clearAuthData();
      }
    }
  }

  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["User", "Auth"],
  endpoints: () => ({}),
});

export const clearApiCache = () => {
  api.util.resetApiState();
};

export const { usePrefetch } = api;
