export const menu = {
  organization: [
    "General",
    "Rates",
    // "Job",
    // "Teams",
    "Users",
    // "Feedback",
    // "Naming Conventions",
    // "History",
    // "Features",
    "Billing",
  ],
};
