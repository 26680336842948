import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useCrumbs } from "../../../hooks/useCrumbs";
import { SidebarContainer } from "../../../components";
import Tabs from "../../../shared/Tabs";
import { Card, EditableText } from "../../../shared";
import {
  useGetEmployeeQuery,
  useUpdateEmployeeMutation,
} from "../../../redux/services/employees/employeesApi";
import EmployeeCard from "./EmployeeCard";
import EmployeeJobs from "./Jobs/EmployeeJobs";
import EmployeePayouts from "./Payouts/EmployeePayouts";
// import JobChart from "./JobChart";

/**
 * EmployeeShow component displays detailed information about an employee
 * including their personal info, jobs, and payouts
 * @component
 */
const EmployeeShow = () => {
  const params = useParams();
  const { user } = useAuth();
  const crumbs = useCrumbs();

  // RTK Query hooks
  const { data: employee = {}, isLoading } = useGetEmployeeQuery({
    id: params.id,
  });
  const [updateEmployee] = useUpdateEmployeeMutation();

  const [reportData, setReportData] = useState([]);

  // Update breadcrumb when employee data loads
  useEffect(() => {
    if (employee._id) {
      crumbs.updateCrumbName({
        name: employee.fullName,
        path: "/employees/" + employee._id,
      });
      crumbs.setLoading(false);
    }
  }, [employee._id]);

  /**
   * Handles updating employee information
   * @param {Object} e - Update data object
   */
  async function handleUpdate(e) {
    const res = await updateEmployee({
      id: params.id,
      data: e,
    }).unwrap();
  }

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();

  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderHeader = (headerProps) => (
    <div ref={headerRef} className="p-2 border-b bg-white">
      {headerProps.title}
    </div>
  );

  const tabs = [
    {
      id: "Info",
      content: (
        <div className="h-auto">
          <div className="grid grid-cols-8 gap-3">
            <EmployeeCard
              info={employee}
              employeeStatus={isLoading ? "loading" : "success"}
              handleUpdate={handleUpdate}
            />
          </div>
        </div>
      ),
    },
    {
      id: "Jobs",
      content: (
        <div className="h-auto">
          <EmployeeJobs
            employee={employee}
            employeeStatus={isLoading ? "loading" : "success"}
          />
        </div>
      ),
    },
    {
      id: "Payouts",
      content: (
        <div className="h-auto">
          <EmployeePayouts
            employee={employee}
            employeeStatus={isLoading ? "loading" : "success"}
          />
        </div>
      ),
    },
  ];

  const title = (
    <div className="text-gray-800 font-semibold text-lg flex items-center">
      <EditableText
        className="ml-1"
        iconClasses="h-3 w-3"
        id="fullName"
        value={employee.user?.fullName}
        size="sm"
        displayText={(v) => (
          <span className="text-gray-800 font-semibold text-lg">{v}</span>
        )}
        onSave={(e) => handleUpdate({ fullName: e.value })}
      />
    </div>
  );

  return (
    <SidebarContainer title={title} header={renderHeader} hideSidebar>
      {({ parentHeight, parentWidth }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
        const panelStyle = {
          height: `${parentHeight - offsetTop - offsetHeight - 70}px`,
        };

        return (
          <Tabs
            className="w-full overflow-hidden"
            style={wrapperStyle}
            panelStyle={panelStyle}
            useSearchParams
            panelClasses="p-2 border-t overflow-y-scroll"
            tabListClasses="bg-white px-2 h-[38px]"
            tabclassName="mx-1"
            id="employee-show"
            data={tabs}
          />
        );
      }}
    </SidebarContainer>
  );
};

export default EmployeeShow;
