import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "../../../shared";
import { useAuth } from "../../../hooks/useAuth";
import useForm from "../../../hooks/useForm";
import {
  fetchClients,
  selectAllClients,
} from "../../../redux/reducers/clients/clientsSlice";
import { useNavigate } from "react-router-dom";
import { useAddEquipmentMutation } from "../../../redux/services/equipment";
import EquipmentCreateForm from "./EquipmentCreateForm";

const EquipmentCreateModal = ({
  modalOpen,
  setModalOpen,
  onSuccess,
  clientId,
  disableNavigation,
  ...props
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { form, updateForm, clearForm, setFormInit } = useForm({
    initialValues: {
      name: "",
      type: "",
      status: "",
      serialNumber: "",
      model: "",
      manufacturer: "",
      purchaseDate: "",
      notes: "",
      client: null,
    },
  });
  const [searchTerm, setSearchTerm] = useState("");

  const [addEquipment, { isLoading: addingEquipment }] =
    useAddEquipmentMutation();

  const clients = useSelector(selectAllClients);
  const clientsStatus = useSelector((state) => state.clients.status);

  useEffect(() => {
    if (!clientId && clientsStatus === "idle") {
      dispatch(fetchClients(user.parentCompany));
    } else if (clientId) {
      const selectedClient = clients.find((client) => client._id === clientId);
      updateForm({ id: "client", value: clientId });
      if (selectedClient) {
        setSearchTerm(selectedClient.name || "");
      }
    }
  }, [clientsStatus, user, clientId, clients]);

  function closeModal() {
    setModalOpen(false);
    handleCancel();
  }

  function handleCancel() {
    clearForm("client");
  }

  function handleSelectClient(client) {
    updateForm({ id: "client", value: client._id });
    setSearchTerm(client.name || "");
  }
  function setForm(obj, custom) {
    setFormInit(custom ? obj : {}, { setErrors: true, reset: true });
    return;
  }

  async function saveEquipment() {
    const getValue = (field) => {
      if (Array.isArray(field)) return field;
      if (!field) return null;
      return typeof field === "object" ? field.value : field;
    };

    const postData = {
      ...form,
      name: form.name || "",
      type: getValue(form.type),
      status: getValue(form.status),
      serialNumber: form.serialNumber || "",
      model: form.model || "",
      manufacturer: form.manufacturer || "",
      purchaseDate: form.purchaseDate || null,
      notes: form.notes || "",
      client: form.client || null,
    };

    // Remove any null/undefined values
    Object.keys(postData).forEach((key) => {
      if (postData[key] === null || postData[key] === undefined) {
        delete postData[key];
      }
    });

    try {
      const res = await addEquipment(postData).unwrap();

      if (res.status === "success") {
        if (onSuccess) {
          onSuccess(res.data);
        }
        closeModal();
        if (!disableNavigation) {
          navigate("/equipment/" + res.data._id);
        }
      }
    } catch (error) {
      console.error("Error saving equipment:", error);
      // Handle error appropriately
    }
  }

  const createModalContent = (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        saveEquipment();
      }}
    >
      <EquipmentCreateForm
        form={form}
        updateForm={updateForm}
        job={props.job}
        clientId={clientId}
        clients={clients}
        setForm={setForm}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSelectClient={handleSelectClient}
      />
    </form>
  );

  return (
    <Modal
      visible={modalOpen}
      setVisible={setModalOpen}
      onClose={closeModal}
      title="Create Equipment"
      footer={
        <div>
          <Button className="mr-3" onClick={closeModal} type="alternative">
            Cancel
          </Button>
          <Button
            loading={addingEquipment}
            disabled={addingEquipment}
            onClick={saveEquipment}
            type="primary"
          >
            Submit
          </Button>
        </div>
      }
      content={createModalContent}
      footerPosition="right"
      contentWrapperclassName="p-3 lg:p-6 space-y-6"
    />
  );
};

export default EquipmentCreateModal;
