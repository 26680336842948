import { api } from "../../api";

export const configApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getConfig: builder.query({
      query: ({ parentCompany }) => ({
        url: `/config/${parentCompany}?type=parent`,
      }),
    }),
    fetchConfig: builder.mutation({
      query: ({ parentCompany }) => ({
        url: `/config/${parentCompany}?type=parent`,
        method: "get",
      }),
    }),
    createConfig: builder.mutation({
      query: ({ parentCompany }) => ({
        url: `/config/${parentCompany}?type=parent`,
        method: "post",
        body: JSON.stringify({}),
      }),
    }),
  }),
});

export const {
  useGetConfigQuery,
  useCreateConfigMutation,
  useFetchConfigMutation,
} = configApi;
