import { useForm } from "../hooks";
import { useGetEmployeesQuery } from "../redux/services/employees/employeesApi";
import { DatePicker, TimeInput, TextArea, Button } from "../shared";
import { formatDateOrTime } from "../utils";
import { AssignedToSelect } from "./AssignedToSelect";

export const BookForm = ({ form, updateForm }) => {
  const { data: employees } = useGetEmployeesQuery({ role: "Technician" });

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="col-span-2">
        <AssignedToSelect
          onChange={(value) => updateForm({ id: "assignedTo", value })}
          value={form.assignedTo}
          users={employees?.employees || []}
        />
      </div>
      <div className="col-span-1">
        <DatePicker
          id="appointmentDate"
          name="appointmentDate"
          label="Appointment Date"
          value={formatDateOrTime(form.appointmentDate, "date")}
          onChange={(value) => updateForm({ id: "appointmentDate", value })}
          required
        />
      </div>
      <div className="col-span-1">
        <TimeInput
          id="appointmentTime"
          name="appointmentTime"
          label="Appointment Time"
          value={formatDateOrTime(form.appointmentDate, "time")}
          onChange={updateForm}
          required
        />
      </div>
      <div className="col-span-2">
        <TextArea
          id="notes"
          name="notes"
          label="Notes"
          placeholder="Add any additional notes..."
          value={form.notes}
          onChange={updateForm}
        />
      </div>
    </div>
  );
};
