import Card from "../../../shared/Card";
import { renderAmount, renderTotalMileage } from "../../../utils";
import { isAuthorized } from "../../../utils/authority";

// Common keys for both types
const commonKeys = [
  { title: "Invoice Total", key: "total", type: "amount" },
  { title: "Other Charges", key: "totalOtherCharges", type: "amount" },
];

// Trucking specific keys
const truckingKeys = [
  { title: "Total Mileage", key: "totalMileage", type: "mi" },
  { title: "Total Weight", key: "totalWeight", type: "lbs" },
  { title: "Base Charges", key: "totalBaseCharges", type: "amount" },
  { title: "Drop Charges", key: "totalDropCharges", type: "amount" },
  { title: "Fuel Charges", key: "totalFuelCharges", type: "amount" },
  { title: "Total Standby Time", key: "totalStandByTime", type: "hrs" },
];

// Service repair specific keys
const serviceRepairKeys = [
  { title: "Parts Total", key: "totalParts", type: "amount" },
  { title: "Labor Total", key: "totalLabor", type: "amount" },
];

const InvoiceTotals = ({ info, appType, ...props }) => {
  // Determine which keys to use based on appType
  const keys = [
    ...commonKeys,
    ...(appType === "trucking" ? truckingKeys : serviceRepairKeys),
  ];

  return (
    <>
      <Card className="row-span-2 px-10 py-4">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-3 lg:grid-cols-4 xl:grid-cols-9">
          {keys.map((item) => (
            <div className="flex flex-col">
              <span className="text-gray-500 mb-1 font-semibold">
                {item.title}
              </span>
              <span className="font-semibold text-lg">
                <p>
                  {item.type === "amount"
                    ? renderAmount(info[item.key])
                    : item.type === "mi"
                    ? `${renderTotalMileage(info.totalMileage)} mi`
                    : item.type === "hrs"
                    ? `${info.totalStandByTime || 0} hrs`
                    : item.type === "lbs"
                    ? `${info.totalWeight || 0} lbs`
                    : info[item.key] || 0}
                </p>
              </span>
            </div>
          ))}
        </div>
      </Card>

      {/* <Modal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        title="Table Preferences"
        footer={modalFooter}
        content={modalContent}
        size="md"
        footerPosition="right"
      /> */}
    </>
  );
};

export default InvoiceTotals;
