import { useEffect } from "react";
import {
  Input,
  Select,
  TextArea,
  DatePicker,
  InputDropdown,
} from "../../../shared";
import { formatDate } from "../../../utils";
import { AvatarClient } from "../../../components";

const STATUS_OPTIONS = [
  { value: "Available", label: "Available" },
  { value: "In Use", label: "In Use" },
  { value: "Under Maintenance", label: "Under Maintenance" },
  { value: "Out of Service", label: "Out of Service" },
];

const EquipmentCreateForm = ({
  form = {},
  updateForm,
  clients = [],
  clientId,
  searchTerm = "",
  setSearchTerm,
  handleSelectClient,
}) => {
  // Add useEffect to handle clientId prop

  useEffect(() => {
    if (clientId) {
      const selectedClient = clients.find((c) => c._id === clientId);
      if (selectedClient) {
        updateForm({ id: "client", value: clientId });
      }
    }
  }, [clientId, clients, updateForm]);

  // Helper function to get value from form object
  const getValue = (valueId) => {
    if (!form[valueId]) return "";
    return form[valueId];
  };

  // Set form values as variables for easier access
  const name = getValue("name");
  const type = getValue("type");
  const serialNumber = getValue("serialNumber");
  const model = getValue("model");
  const manufacturer = getValue("manufacturer");
  const purchaseDate = getValue("purchaseDate");
  const status = getValue("status");
  const notes = getValue("notes");
  const client = getValue("client");
  const odometer = getValue("odometer");

  // Function to handle form updates
  const handleChange = (e) => {
    updateForm({ id: e.id || e.target.id, value: e.value });
  };

  // Function to handle select values
  const getSelectValue = (field) => {
    if (!field) return null;
    const option = STATUS_OPTIONS.find((opt) => opt.value === field);
    return option || null;
  };

  // Add helper to get client name
  const getClientName = (clientId, clients) => {
    if (!clientId || !clients.length) return "";
    const selectedClient = clients.find((c) => c._id === clientId);
    return selectedClient ? selectedClient.name : "";
  };

  // Add helper to format equipment type
  const formatEquipmentType = (type) => {
    return type?.startsWith("-") ? type.substring(1).trim() : type;
  };

  // Add helper to get status value
  const getStatusValue = (status) => {
    return status?.value || status || "";
  };

  // Function to render client information or search dropdown
  const renderClientSection = () => {
    // If clientId is passed, don't show any client selection UI
    if (clientId) {
      return null;
    }

    // If client is selected through normal flow
    if (form.client) {
      const displayName = getClientName(form.client, clients);
      return (
        <Input
          label="Client"
          value={displayName}
          disabled
          rightElement={
            <button
              onClick={() => {
                updateForm({ id: "client", value: null });
                setSearchTerm("");
              }}
              className="text-red-500 hover:text-red-700"
            >
              ✕
            </button>
          }
        />
      );
    }

    // Show dropdown for client selection if no client is selected
    return (
      <InputDropdown
        label="Client"
        onChange={(e) => setSearchTerm(e.value)}
        value={searchTerm}
        placeholder="Search clients by name"
        data={clients.map((client) => ({
          key: client._id,
          label: client.name || "Unnamed Client",
          value: client.name || "Unnamed Client",
          item: client,
          ...client,
        }))}
        renderItem={(e) => (
          <AvatarClient
            onClick={() => handleSelectClient(e.item)}
            {...e}
            {...e.item}
            disableCancel
          />
        )}
      />
    );
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4 w-full">
        <Input
          label="Equipment Name"
          value={name}
          onChange={(e) => handleChange({ id: "name", value: e.value })}
          required
          className="w-full"
        />
        <Select
          label="Equipment Type"
          value={formatEquipmentType(type)}
          onChange={(value) => handleChange({ id: "type", value: value.value })}
          options={[
            { value: "lps/gas", label: "LPS/Gas" },
            { value: "electric", label: "Electric" },
          ]}
          required
          className="w-full"
        />
      </div>
      <Input
        label="Serial Number"
        value={serialNumber}
        onChange={(e) => handleChange({ id: "serialNumber", value: e.value })}
      />
      <div className="grid grid-cols-2 gap-4 w-full">
        <Input
          label="Model"
          value={model}
          onChange={(e) => handleChange({ id: "model", value: e.value })}
        />
        <Input
          label="Manufacturer"
          value={manufacturer}
          onChange={(e) => handleChange({ id: "manufacturer", value: e.value })}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 w-full">
        <Input
          label="Hour Meter"
          type="number"
          value={odometer}
          onChange={(e) => handleChange({ id: "odometer", value: e.value })}
          placeholder="Enter current hour meter reading"
        />
        <DatePicker
          label="Purchase Date"
          value={formatDate(purchaseDate)}
          onChange={(e) => handleChange({ id: "purchaseDate", value: e.value })}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 w-full">
        <Select
          label="Status"
          value={getStatusValue(status)}
          onChange={(value) =>
            handleChange({ id: "status", value: value.value })
          }
          options={STATUS_OPTIONS}
          required
        />
        {renderClientSection()}
      </div>
      <TextArea
        label="Notes"
        value={notes}
        onChange={(e) => handleChange({ id: "notes", value: e.value })}
      />
    </div>
  );
};

export default EquipmentCreateForm;
