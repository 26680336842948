import { renderCustomColumnDefs } from "../../../../../shared/Table/table.utils";
import { renderAmount, renderTotalMileage } from "../../../../../utils";

// Config for table and form
const tableConfig = [
  {
    Header: "Jobs",
    id: "jobs",
    cell: ({ getValue }) => getValue().length, //`${getValue()?.toLocaleString("en-US")} MI`,
    minSize: 120,
  },
  // {

  // },

  {
    id: "totalOtherCharges",
    header: "Other Charges",
    cell: ({ getValue }) => renderAmount(getValue()),
    size: 100,
  },
  {
    Header: "Total",
    id: "total",
    cell: ({ getValue }) => renderAmount(getValue()),
    size: 120,
  },
];

export const TOTAL_COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
