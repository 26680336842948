import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../shared/Table/table.utils";
import { capitalizeFirstLetter, formatDate } from "../../utils";
import { Badge, Popover } from "../../shared";
import { isAuthorized } from "../../utils/authority";

// Config for table and form
const tableConfig = [
  {
    accessorKey: "name",
    id: "name",
    visible: true,
    required: true,
    minWidth: 200,
    editable: isAuthorized("owner,admin"),
    cell: ({ getValue, row: { original } }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/equipment/${original._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  {
    id: "type",
    enableGlobalFilter: true,
    visible: true,
    cell: (info) => {
      const value = info.getValue();
      return <Badge label={capitalizeFirstLetter(value)}></Badge>;
    },
  },
  {
    id: "status",
    enableGlobalFilter: true,
    visible: true,
    cell: (info) => {
      const value = info.getValue();
      return <Badge label={capitalizeFirstLetter(value)}></Badge>;
    },
  },
  {
    id: "model",
    cell: (info) => {
      const value = info.getValue();
      return value || "-";
    },
    enableGlobalFilter: true,
    visible: true,
    minWidth: 140,
  },
  {
    id: "manufacturer",
    cell: (info) => {
      const value = info.getValue();
      return value || "-";
    },
    enableGlobalFilter: true,
    visible: true,
    minWidth: 140,
  },
  {
    id: "serialNumber",
    cell: (info) => {
      const value = info.getValue();
      return value || "-";
    },
    enableGlobalFilter: true,
    visible: true,
    minWidth: 140,
  },
  {
    id: "purchaseDate",
    enableGlobalFilter: true,
    visible: true,
    cell: (info) => {
      const value = info.getValue();
      return value && formatDate(new Date(value), null, true);
    },
    width: 180,
  },
  {
    id: "lastMaintenanceDate",
    enableGlobalFilter: true,
    visible: true,
    cell: (info) => {
      const value = info.getValue();
      return value && formatDate(new Date(value), null, true);
    },
    width: 180,
  },
  {
    id: "nextMaintenanceDate",
    enableGlobalFilter: true,
    visible: true,
    cell: (info) => {
      const value = info.getValue();
      return value && formatDate(new Date(value), null, true);
    },
    width: 180,
  },
  {
    id: "notes",
    minWidth: 140,
  },
  {
    id: "_id",
    header: "id",
    visible: false,
    disableGlobalFilter: true,
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
export const FORM_OPTIONS = {
  validations: renderValidations(tableConfig, "equipment"),
};
export const PAGE_SIZE_OPTIONS = [
  { label: "10 Equipment", value: 10 },
  { label: "20 Equipment", value: 20 },
  { label: "50 Equipment", value: 50 },
  { label: "100 Equipment", value: 100 },
  { label: "150 Equipment", value: 150 },
];
