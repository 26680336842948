import { COLUMN_DEFINITIONS as serviceColumnDefs } from "./service.table.config";
import { COLUMN_DEFINITIONS as truckingColumnDefs } from "./trucking.table.config";

/**
 * Returns the appropriate table configuration based on the job type
 * @param {string} type - The type of job ('serviceRepair' or 'trucking')
 * @returns {Object} The table configuration object containing columnDefs, formOptions, and pageSizeOptions
 */
export const getTableConfig = (type) => {
  switch (type) {
    case "serviceRepair":
      return {
        COLUMN_DEFINITIONS: serviceColumnDefs,
      };
    case "trucking":
      return {
        COLUMN_DEFINITIONS: truckingColumnDefs,
      };
    default:
      return {};
  }
};

export default getTableConfig;
