import {
  getClient,
  getEquipment,
  getInventoryItem,
  getMaintenanceHistory,
} from "./models";

// Handler for equipment records
// Processes equipment data and maintains client relationships
// Returns null if equipment already exists to avoid duplicates
export const equipment = (current, { customGroupsData, keyMappings }) => {
  // Extract equipment and client data from current record
  const { key: uniqueEquipmentId, data: equipmentData } = getEquipment(
    current,
    { keyMappings: keyMappings.equipmentKeys }
  );
  const { key: uniqueClientId, data: clientData } = getClient(current, {
    keyMappings: keyMappings.clientKeys,
  });

  customGroupsData.client = customGroupsData.client || new Map();
  customGroupsData.equipment = customGroupsData.equipment || new Map();

  // Check if client exists in map, add if not
  const doesClientExist = customGroupsData.client?.get(uniqueClientId);
  if (!doesClientExist) {
    customGroupsData.client?.set(uniqueClientId, clientData);
  }

  // Check if equipment exists, add if not
  const doesEquipmentExist = customGroupsData.equipment?.get(uniqueEquipmentId);

  if (!doesEquipmentExist) {
    customGroupsData.equipment?.set(uniqueEquipmentId, {
      ...equipmentData,
      client: clientData,
    });
    return {
      key: uniqueEquipmentId,
      data: equipmentData,
    };
  }

  // Return null if equipment already exists
  return {
    key: null,
    data: null,
  };
};

// Handler for maintenance history records
// Associates maintenance records with equipment and clients
export const maintenanceHistory = (
  current,
  { customGroupsData, keyMappings }
) => {
  // Get related client and equipment data
  const { key: uniqueClientId } = getClient(current, {
    keyMappings: keyMappings.clientKeys,
  });
  const { key: uniqueEquipmentId } = getEquipment(current, {
    keyMappings: keyMappings.equipmentKeys,
  });
  // const parts = inventoryMap.get(uniqueEquipmentId);

  // Get maintenance history data and associate with client/equipment
  const { key: uniqueMaintenanceHistoryId, data: maintenanceHistoryData } =
    getMaintenanceHistory(current, {
      keyMappings: keyMappings.maintenanceHistoryKeys,
    });

  customGroupsData.inventoryItems =
    customGroupsData.inventoryItems || new Map();

  if (customGroupsData.equipment?.has(uniqueEquipmentId)) {
    maintenanceHistoryData.equipment =
      customGroupsData.equipment.get(uniqueEquipmentId);
  }

  if (customGroupsData.client?.has(uniqueClientId)) {
    maintenanceHistoryData.client = customGroupsData.client.get(uniqueClientId);
  }

  // Check if maintenance history exists, add if not
  const doesMaintenanceHistoryExist = customGroupsData.maintenanceHistory?.get(
    uniqueMaintenanceHistoryId
  );
  if (!doesMaintenanceHistoryExist) {
    customGroupsData.maintenanceHistory?.set(
      uniqueMaintenanceHistoryId,
      maintenanceHistoryData
    );
    return {
      key: uniqueMaintenanceHistoryId,
      data: maintenanceHistoryData,
    };
  }

  // Return null if maintenance history already exists
  return {
    key: null,
    data: null,
  };
};
// Handler for inventory/parts records
// Associates parts with equipment and maintains global parts inventory
export const inventoryItems = (current, { customGroupsData, keyMappings }) => {
  // Get equipment and inventory item data
  const { key: uniqueEquipmentId } = getEquipment(current, {
    keyMappings: keyMappings.equipmentKeys,
  });
  const { key: uniqueMaintenanceHistoryId } = getMaintenanceHistory(current, {
    keyMappings: keyMappings.maintenanceHistoryKeys,
  });

  const { key: uniqueInventoryItemId, data: inventoryItemData } =
    getInventoryItem(current, {
      keyMappings: keyMappings.inventoryItemsKeys,
    });

  // Add part to maintenance history's parts list if maintenance history exists
  const maintenanceHistory = customGroupsData.maintenanceHistory?.get(
    uniqueMaintenanceHistoryId
  );
  if (maintenanceHistory) {
    if (!maintenanceHistory.parts) maintenanceHistory.parts = new Map();
    maintenanceHistory.parts.set(uniqueInventoryItemId, inventoryItemData);
    customGroupsData.maintenanceHistory?.set(
      uniqueMaintenanceHistoryId,
      maintenanceHistory
    );
  }

  // Add part to equipment's parts list if equipment exists
  const equipment = customGroupsData.equipment?.get(uniqueEquipmentId);
  if (equipment) {
    if (!equipment.parts) equipment.parts = new Map();
    equipment.parts.set(uniqueInventoryItemId, inventoryItemData);
    customGroupsData.equipment?.set(uniqueEquipmentId, equipment);
  }

  // Add to global inventory if not already present
  // if (!customGroupsData.inventoryItems?.has(uniqueInventoryItemId)) {
  //   customGroupsData.inventoryItems?.set(
  //     uniqueInventoryItemId,
  //     inventoryItemData
  //   );
  // }

  // Check if inventory item exists, add if not
  const doesInventoryItemExist = customGroupsData.inventoryItems?.get(
    uniqueInventoryItemId
  );
  if (!doesInventoryItemExist) {
    customGroupsData.inventoryItems?.set(
      uniqueInventoryItemId,
      inventoryItemData
    );
    return {
      key: uniqueInventoryItemId,
      data: inventoryItemData,
    };
  }

  // Return null if inventory item already exists
  return {
    key: null,
    data: null,
  };
};
