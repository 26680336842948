import React, { useEffect, useRef, useState } from "react";
import DriverCard from "./DriverCard";
import DriverJobs from "./Jobs/DriverJobs";
import DriverPayouts from "./Payouts/DriverPayouts";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import {
  updateDriver,
  fetchDriver,
  resetDriver,
} from "../../../redux/reducers/drivers/driverSlice";
import {
  resetPayouts,
  selectAllPayouts,
} from "../../../redux/reducers/payouts/payoutsSlice";
import { SidebarContainer } from "../../../components";
import Tabs from "../../../shared/Tabs";
import DriverManifests from "./Manifests/DriverManifests";
import { useCrumbs } from "../../../hooks/useCrumbs";
import JobChart from "./JobChart";
import { Card, EditableText } from "../../../shared";
import request from "../../../utils/request";
import { driverJobsStage } from "../../../constants/aggregate";
import DriverReports from "./Reports/DriverReports";

const DriverShow = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const crumbs = useCrumbs();
  const driver = useSelector((state) => state.driver.driver);
  const driverStatus = useSelector((state) => state.driver.status);

  const fullName = driver.fullName;

  const payouts = useSelector(selectAllPayouts);
  const payoutsStatus = useSelector((state) => state.payouts.status);

  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    if (driverStatus === "idle") {
      crumbs.setLoading(true);
      dispatch(
        fetchDriver({ parentId: user.parentCompany, driverId: params.id })
      );
    }
  }, [driverStatus, dispatch, user, params.id]);

  useEffect(() => {
    if (driver._id) {
      fetchReport();

      crumbs.updateCrumbName({
        name: driver.fullName,
        path: "/drivers/" + driver._id,
      });
      crumbs.setLoading(false);
    }
  }, [driver._id]);

  useEffect(() => {
    return () => {
      dispatch(resetDriver());
      dispatch(resetPayouts());
    };
  }, []);

  async function handleUpdate(e) {
    const update = {
      ...e,
    };

    const resUpdate = await dispatch(
      updateDriver({
        data: update,
        parentId: user.parentCompany,
        driverId: params.id,
      })
    ).unwrap();

    // if (resUpdate.status === "success") {
    //   dispatch(driverUpdated(resUpdate.data));
    // }
  }

  const fetchReport = async (view) => {
    const stages = driverJobsStage(
      // "appointment.date",
      "appointmentDate",
      user.parentCompany,
      driver._id
    );

    const response = await request(`/reports`, {
      method: "post",
      data: {
        stages,
        modelName: "Leg",
        config: {
          0: {
            type: "$match",
            // useDate: "appointment.date",
            _id: "driver",
          },
        },
      },
    });
    return Promise.all([response]).then((values) => {
      const finalData = values[0]?.data;

      setReportData(finalData);
    });
  };

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderHeader = (headerProps) => {
    return (
      <div ref={headerRef} className="p-2 border-b bg-white">
        {headerProps.title}
      </div>
    );
  };

  const tabs = [
    {
      id: "Info",
      content: (
        <div className="h-auto">
          <div className="grid grid-cols-8 gap-3">
            <DriverCard
              info={driver}
              driverStatus={driverStatus}
              handleUpdate={handleUpdate}
            />
            <Card className="col-span-3">
              <JobChart data={reportData} />
            </Card>
            {/* <DriverPayouts
              driver={driver}
              driverStatus={driverStatus}
              payouts={payouts}
              payoutsStatus={payoutsStatus}
            /> */}
            {/* <DriverJobs driver={driver} driverStatus={driverStatus} /> */}
          </div>
        </div>
      ),
    },
    {
      id: "Jobs",
      content: (
        <div className="h-auto">
          <DriverJobs driver={driver} driverStatus={driverStatus} />
        </div>
      ),
    },
    {
      id: "Payouts",
      content: (
        <div className="h-auto">
          <DriverPayouts
            driver={driver}
            driverStatus={driverStatus}
            payouts={payouts}
            payoutsStatus={payoutsStatus}
          />
        </div>
      ),
    },
    {
      id: "Manifests",
      content: (
        <div className="h-auto">
          <DriverManifests driver={driver} driverStatus={driverStatus} />
        </div>
      ),
    },
    {
      id: "Reports",
      content: (
        <div className="h-auto">
          <DriverReports driver={driver} driverStatus={driverStatus} />
        </div>
      ),
    },
  ];

  const title = (
    <div className="text-gray-800 font-semibold text-lg flex items-center">
      <EditableText
        className="ml-1"
        iconClasses="h-3 w-3"
        id="fullName"
        value={fullName}
        size="sm"
        displayText={(v) => (
          <span className="text-gray-800 font-semibold text-lg">{v}</span>
        )}
        // loading={modalLoad}
        onSave={(e) => handleUpdate({ fullName: e.value })}
      />
    </div>
  );

  return (
    <SidebarContainer title={title} header={renderHeader} hideSidebar>
      {({ parentHeight, parentWidth }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
        const panelStyle = {
          height: `${parentHeight - offsetTop - offsetHeight - 70}px`,
        };
        return (
          <Tabs
            className={`w-full overflow-hidden`}
            style={wrapperStyle}
            panelStyle={panelStyle}
            useSearchParams
            panelClasses={`p-2 border-t overflow-y-scroll`}
            tabListClasses="bg-white px-2 h-[38px]"
            tabclassName="mx-1"
            id="driver-show"
            // onClick={handleTabClick}
            data={tabs}
          />
        );
      }}
      {/* </SidebarContainer>
        return (
          <div style={wrapperStyle} className="px-3 pb-3 h-auto">
            {clientStatus === "loading" ? (
              <Spinner />
            ) : (
              <div className="grid grid-cols-1 gap-4">
                <Tabs
                  default={getDefaultTab()}
                  onClick={handleTabClick}
                  data={tabs}
                />
              </div>
            )}
          </div>
        );
      }} */}
    </SidebarContainer>
  );
};

export default DriverShow;
