import { renderCustomColumnDefs } from "../../../../../shared/Table/table.utils";
import { capitalizeAll, formatDate, renderAmount } from "../../../../../utils";

// Config for table and form
const tableConfig = [
  // {
  //   Header: "#",
  //   accessor: "",
  //   Cell: (row) => {
  //     return row.row.id * 1 + 1;
  //   },
  //   // disableSortBy: true,
  //   // disableFilters: true,
  // },
  {
    id: "appointmentDate",
    header: "Appointment Date",
    cell: ({ getValue }) => formatDate(getValue()),
    size: 120,
    columns: [],
  },
  {
    id: "jobId",
    minSize: 120,
    cell: ({ getValue }) => getValue(),
  },
  {
    id: "performedBy",
    minSize: 100,
    cell: ({ getValue }) => {
      return getValue()?.[0]?.firstName;
      return capitalizeAll(getValue()?.firstName);
    },
  },
  { id: "loadType", width: 120, cell: ({ getValue }) => getValue() },
  {
    id: "weight",
    cell: ({ getValue }) => {
      return `${getValue()?.toLocaleString("en-US")} lbs`;
    },
    minSize: 120,
    size: 90,
  },
  {
    id: "baseCharge",
    cell: ({ getValue }) => renderAmount(getValue()),
    size: 120,
  },
  {
    id: "dropCharge",
    cell: ({ getValue }) => renderAmount(getValue()),
    size: 120,
  },
  {
    id: "waitTime",
    header: "StandBy Time",
    minSize: 110,
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value) return "";
      return `${value?.total} hrs`;
    },
  },
  {
    id: "otherCharges",
    cell: ({ getValue }) => renderAmount(getValue()),
    minSize: 110,
  },
  {
    id: "fuelSurcharge",
    cell: ({ getValue }) => renderAmount(getValue()),
    minSize: 120,
  },
  {
    id: "totalCharges",
    cell: ({ getValue }) => renderAmount(getValue()),
    minSize: 130,
  },
  { id: "legs", visible: false },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
