import { XMarkIcon } from "@heroicons/react/24/outline";
import Avatar from "../shared/Avatar";
import classNames from "classnames";

export const AvatarClient = (props) => {
  const wrapperClasses = classNames(
    props.className,
    "flex items-center justify-between rounded-lg py-2 px-1 cursor-pointer",
    {
      "hover:bg-gray-100": !props.disableCancel,
    }
  );

  return (
    <div onClick={props.onClick} className={wrapperClasses}>
      <div className="flex items-center space-x-2">
        <Avatar size="xs" src={props.avatar} />
        <span className="font-semi-bold text-sm">{props.name}</span>
        <span className="text-gray-500">{`(${props.slug?.toUpperCase()})`}</span>
      </div>
      {!props.disableCancel && (
        <div>
          {props.selected && (
            <XMarkIcon onClick={props.onCancel} className="w-5 h-5" />
          )}
        </div>
      )}
    </div>
  );
};
