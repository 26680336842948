import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../../utils/request";
import Avatar from "../../../shared/Avatar";

const initialState = {
  clients: [],
  status: "idle",
  error: null,
};

export const fetchClients = createAsyncThunk(
  "clients/fetchClients",
  async (parentId, { rejectWithValue }) => {
    const res = await request(`/clients?parent=${parentId}`);
    if (!res.data.clients) {
      rejectWithValue(res.data);
      return;
    }
    return res.data;
  }
);

export const deleteClients = createAsyncThunk(
  "clients/deleteClients",
  async ({ data, parentId }, { rejectWithValue }) => {
    const res = await request(`/clients?parent=${parentId}`, {
      method: "delete",
      data,
    });
    if (!res.data.clients) {
      rejectWithValue();
    }
    return res.data;
  }
);

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    resetClients: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchClients.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.clients = [];
        state.status = "succeeded";
        state.clients = state.clients.concat(action.payload.clients);
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteClients.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteClients.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { data } = action.payload;
        const newClients = state.clients.filter((x) => !data.includes(x._id));
        state.clients = newClients;
      })
      .addCase(deleteClients.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetClients } = clientsSlice.actions;

export default clientsSlice.reducer;

export const selectAllClients = (state) => state.clients.clients;

export const selectClientsOptions = (state) => {
  return state.clients.clients.map((obj) => {
    return {
      value: obj,
      label: obj.name,
    };
  });
};

export const selectClientsAvatarOptions = (state) => {
  return state.clients.clients.map((obj) => {
    return {
      value: obj,
      label: (
        <div className="flex items-center space-x-2">
          <Avatar size="sm" src={obj.avatar} />
          <div>{obj.name}</div>
        </div>
      ),
    };
  });
};

export const selectClientsLocations = (state, clientId) => {
  if (!clientId) return;
  return state.clients.clients
    .filter((client) => client._id === clientId)[0]
    .locations.map((obj) => {
      return {
        value: obj.address,
        label: obj.address,
      };
    });
};
