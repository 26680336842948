import {
  ClockIcon,
  DocumentIcon,
  DocumentTextIcon,
  CheckCircleIcon,
  TruckIcon,
  UserIcon,
  CalendarIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { getAppType } from "../../utils";
import { BookForm } from "../../components/BookForm";
import { AssignedToSelect } from "../../components";
import { AssignUserForm } from "../../components/AssignUserForm";
// export const jobStages = {
//   1: {
//     order: 1,
//     id: "new",
//     title: "New",
//     next: "pending",
//     button: "Publish",
//     color: "teal",
//   },
//   2: {
//     order: 2,
//     id: "pending",
//     title: "Pending",
//     next: "booked",
//     button: "Book",
//     color: "orange",
//   },
//   3: {
//     order: 3,
//     id: "booked",
//     title: "Booked",
//     next: "enRoute",
//     button: "Start Job",
//     color: "yellow",
//   },
//   4: {
//     order: 4,
//     id: "enRoute",
//     title: "En Route",
//     next: "delivered",
//     button: "Mark Delivered",
//     color: "blue",
//   },
//   5: {
//     order: 5,
//     id: "delivered",
//     title: "Delivered",
//     next: "invoiced",
//     color: "cyan",
//   },
//   6: {
//     order: 6,
//     id: "invoiced",
//     title: "Invoiced",
//     next: "paid",
//     color: "purple",
//   },
//   7: { order: 7, id: "paid", title: "Paid" },
// };
export const jobStages = {
  trucking: {
    1: {
      order: 1,
      id: "new",
      title: "New",
      next: "pending",
      button: "Publish",
      icon: DocumentTextIcon,
    },
    2: {
      order: 2,
      id: "pending",
      title: "Pending",
      next: "enRoute",
      button: "Start Job",
      color: "orange",
      icon: ClockIcon,
    },
    3: {
      order: 3,
      id: "enRoute",
      title: "En Route",
      next: "delivered",
      button: "Mark Delivered",
      color: "blue",
      icon: TruckIcon,
    },
    4: {
      order: 4,
      id: "delivered",
      title: "Delivered",
      next: "invoiced",
      // button: "Create Invoice",
      // modal: "Are you sure you want to create an invoice from this job?",
      color: "cyan",
      icon: CheckCircleIcon,
    },
    5: {
      order: 5,
      id: "invoiced",
      title: "Invoiced",
      color: "purple",
      icon: DocumentIcon,
    },
  },
  serviceRepair: {
    1: {
      order: 1,
      id: "new",
      title: "New",
      next: "pending",
      button: "Publish",
      icon: DocumentTextIcon,
    },
    2: {
      order: 2,
      id: "pending",
      title: "Pending",
      next: ({ form }) => {
        return form.assignedTo.length > 0 ? "assigned" : "booked";
      },
      button: "Book",
      icon: ClockIcon,
      modal: (props) => <BookForm {...props} />,
      color: "orange", // Color for pending status indicating caution
    },
    3: {
      order: 3,
      id: "booked",
      title: "Booked",
      next: "assigned",
      button: "Assign",
      icon: CalendarIcon,
      modal: (props) => <AssignUserForm {...props} />,
      color: "blue", // Color for booked status indicating progress
    },
    4: {
      order: 4,
      id: "assigned",
      title: "Assigned",
      next: "inProgress",
      button: "Start Job",
      icon: UserIcon,
      modal: "Are you sure you want to start this job?",
      color: "yellow", // Unique color for assigned status
    },
    5: {
      order: 5,
      id: "inProgress",
      title: "In Progress",
      next: "completed",
      button: "Mark Completed",
      icon: WrenchIcon,
      color: "cyan", // Color for in progress status indicating active work
    },
    6: {
      order: 6,
      id: "completed",
      title: "Completed",
      next: "invoiced",
      icon: CheckCircleIcon,
      color: "green", // Color for completed status indicating success
    },
    7: {
      order: 7,
      id: "invoiced",
      title: "Invoiced",
      icon: DocumentIcon,
      color: "purple", // Color for invoiced status indicating finalization
    },
  },
};

export const stageColors = {
  text: {
    orange: "text-orange-500",
    blue: "text-blue-500",
    yellow: "text-yellow-500",
    cyan: "text-cyan-500",
    gray: "text-gray-500",
    green: "text-green-500",
    purple: "text-purple-500",
  },
  bg: {
    orange: "bg-orange-100",
    blue: "bg-blue-100",
    yellow: "bg-yellow-100",
    cyan: "bg-cyan-100",
    gray: "bg-gray-100",
    green: "bg-green-100",
    purple: "bg-purple-100",
  },
  border: {
    orange: "border-orange-300",
    blue: "border-blue-300",
    yellow: "border-yellow-300",
    cyan: "border-cyan-300",
    gray: "border-gray-300",
    green: "border-green-300",
    purple: "border-purple-300",
  },
};

export const getStages = (type = getAppType()) => {
  return jobStages[type];
};

export const getStage = (key, type = getAppType()) => {
  let res = {};
  if (typeof key === "string") {
    const current = Object.keys(jobStages[type]).find(
      (i) => jobStages[type][i].id === key || jobStages[type][i].title === key
    );
    res = jobStages[type][current] || {};
  } else {
    res = jobStages[type][key] || {};
  }
  return res;
};

export const getNextStage = (key, options = {}, type = getAppType()) => {
  if (typeof key === "string") {
    const current = Object.keys(jobStages[type]).find(
      (i) => jobStages[type][i].id === key
    );
    if (!jobStages[type][current].next) {
      return null;
    }
    if (typeof jobStages[type][current].next === "function") {
      return getStage(
        jobStages[type][current].next({ status: key, ...options }),
        type
      );
    }
    return getStage(Number(jobStages[type][current].order) + 1, type);
  }
  return getStage(Number(key) + 1, type);
};
