import classNames from "classnames";
import { useState, useRef } from "react";
import { usePopper } from "react-popper";
import { Portal } from "react-portal";
import {
  PopoverButton,
  Popover as PopoverEl,
  PopoverPanel,
  Transition,
} from "@headlessui/react";

// function useHover() {
//   const [isHovered, setHovered] = useState(false);

//   const bind = useMemo(
//     () => ({
//       onMouseEnter: () => setHovered(true),
//       onMouseLeave: () => setHovered(false),
//     }),
//     []
//   );

//   return [isHovered, bind];
// }

export const Popover = (props) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: props.position || "bottom",
    modifiers: [
      { name: "offset", options: { offset: [0, 8] } },
      { name: "arrow", options: { element: arrowElement } },
    ],
  });

  const popClasses = classNames(
    "z-10 text-sm font-light text-gray-500 py-1 px-2",
    "transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm",
    props.className
  );
  return (
    <PopoverEl>
      {({ open }) => (
        <>
          <div ref={setTargetElement}>
            <PopoverButton>{props.trigger}</PopoverButton>
          </div>

          <Portal>
            <div
              id="tooltip"
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => setPopperElement(null)}
              >
                <PopoverPanel static className={popClasses}>
                  {props.content || <div className="p-3">{props.children}</div>}
                  <div
                    ref={setArrowElement}
                    id="arrow"
                    style={styles.arrow}
                    className="shadow-sm"
                  />
                </PopoverPanel>
              </Transition>
            </div>
          </Portal>
        </>
      )}
    </PopoverEl>
  );
};

export default Popover;
