export default {
  equipmentKeys: {
    serialNumber: ["Serial No.", "Serial", "Serial NO."],
    name: ["Unit No.", "Unit NO.", "Unit Information"],
    make: ["Make"],
    model: ["Model"],
    odometer: ["Hour Meter reading", "HR Meter Reading", "Hour Meter Reading"],
    type: ["Unit Type"],
  },
  maintenanceHistoryKeys: {
    originalCall: ["Original Call", "Original Customer Concern"],
    summary: (record) => {
      let summary =
        record["Work Performed"] ||
        record["Job Description"] ||
        record["Description of Repairs Made"];

      const submissionForm = record["Submission Form Name"];

      if (submissionForm === "Planned Maintenance Order") {
        summary =
          "CHECK OPERATION OF CONTROLS, CLEAN UNIT WITH AIR PRESSURE, CHANGE AIR FILTER, CHECK BREAKS/ EMERGENCY BREAK, CHECK FOR HYDRAULIC LEAKS, CHECK FOR ENGINE OIL LEAKS, CHECK TRANSMISSION OPERATION, WIRING, HORN, FUSES, GAUGES, CHECK FUEL SYSTEM, CHECK RADIATOR/ WATER LEAKS, CHECK STEERING AND LINKAGE, HYDRAULIC/TRANSMISSION FLUIDS, BRAKE/ COOLANT FLUID LEVEL, CHANGE MOTOR OIL/ FILTER, GREASE ALL FITTINGS, CHECK BATTERY/ CLEAN TERMINALS, CHECK MAST ASSEMBLY/ CARRIAGE, CHECK DRIVE AND STEER TIRES, VISUAL INSPECTION/ MISCELLANEOUS, TEST DRIVE UNIT";
      }

      if (
        submissionForm === "PM Work Order OchoaMH" ||
        submissionForm === "PM Work Order"
      ) {
        summary =
          "Test Drive Unit, check operation of controls. Clean entire unit with air pressure. Check for hydraulic leaks. Check brake system. Check steering gear and linkage. Check mast assemble, carriage, rollers, chains and forks. Check all contact assemblies, switches, segments, springs and shunts in control circuit. Check hydraulic oil level. Check differential housing and steering system. Check fuse, all connections and inspect for faulty wiring. Visually inspect unit for worn parts, broken bolts, damage parts etc. Lubricate all grease fittings. Check battery connector, and battery water level. Inspect battery and battery compartment for acid leaks and corrosion. Check tires, load and caster wheels for ware and damage. Inspect steering, axle and tie rod ends. Test drive unit after PM.";
        // ("Test drive unit and check operation after PM.	Test Drive Unit, check operation of controls. Clean entire unit with air pressure. Check for hydraulic leaks. Check brake system. Check steering gear and linkage. Check mast assemble, carriage, rollers, chains and forks. Check all contact assemblies, switches, segments, springs and shunts in control circuit. Check hydraulic oil level. Check differential housing and steering system. Check fuse, all connections and inspect for faulty wiring. Visually inspect unit for worn parts, broken bolts, damage parts etc. Lubricate all grease fittings. Check battery connector, and battery water level. Inspect battery and battery compartment for acid leaks and corrosion. Check tires, load and caster wheels for ware and damage. Inspect steering, axle and tie rod ends. Test drive unit after PM.");
      }
      return summary;
    },
    recommendations: "Recommendations",
    performedBy: "Technician",
    appointmentDate: "Response Date/Time",
    hours: ["Labor", "Labor Time"],
    adjustedHours: "Total Labor",
  },

  inventoryItemsKeys: {
    partNumber: ["Part Number", "Part/Service Number"],
    description: ["Description", "Part/Service Description"],
    quantity: ["Qty", "QTY"],
  },

  clientKeys: {
    name: "Customer Name",
    email: "Customer Email Address",
    phone: "Customer Phone",
    address: "Customer Address",
    city: "City",
    state: "State",
    zipCode: ["ZIP Code", "Zip"],
  },
};
