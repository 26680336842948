function cleanKeys(record) {
  return Object.entries(record).reduce((acc, [key, value]) => {
    const trimmedKey = key.trim().replace(/^["']|["']$/g, "");
    acc[`${trimmedKey}`] = value;
    return acc;
  }, {});
}

/**
 * Gets a value from a record by key, handling both quoted and unquoted keys
 *
 * @example
 * // Single key
 * getValue({"First Name": "John"}, "First Name") // Returns "John"
 * getValue({'"First Name"': "John"}, "First Name") // Returns "John"
 *
 * @example
 * // Multiple possible keys
 * getValue(
 *   {"Serial No.": "123"},
 *   ["Serial No.", "Serial", "Serial NO."]
 * ) // Returns "123"
 *
 * getValue(
 *   {'"Serial No."': "123"},
 *   ["Serial No.", "Serial", "Serial NO."]
 * ) // Returns "123"
 *
 * @param {Object} record - The record object to search
 * @param {string|string[]} key - Key or array of keys to search for
 * @param {Object} options - Additional options (reserved for future use)
 * @returns {string|null} The found value or null if not found
 */
export const getValue = (record, key, options = {}) => {
  // If key is a function, execute it with the record
  if (typeof key === "function") {
    return key(record);
  }
  if (Array.isArray(key)) {
    for (const k of key) {
      const value = record[k]; // || record[k.replace(/^["']|["']$/g, "")];
      if (value) return value;
    }
    return null;
  }

  return record[key]; // || record[key.replace(/^["']|["']$/g, "")];
};

export const getClient = (record, options = { keyMappings }) => {
  record = cleanKeys(record);

  const name = getValue(record, options.keyMappings.name);
  const email = getValue(record, options.keyMappings.email);
  const phone = getValue(record, options.keyMappings.phone);

  const address = getValue(record, options.keyMappings.address);
  const city = getValue(record, options.keyMappings.city);
  const state = getValue(record, options.keyMappings.state);
  const zipCode = getValue(record, options.keyMappings.zipCode);

  const uniqueId = `${name}`;

  return {
    key: uniqueId,
    data: {
      name,
      email,
      phone,
      address: {
        address,
        city,
        state,
        zipCode,
      },
    },
  };
};

export const getEquipment = (record, options = { keyMappings }) => {
  record = cleanKeys(record);

  const serialNumber = getValue(record, options.keyMappings.serialNumber);

  const name = getValue(record, options.keyMappings.name);

  const make = getValue(record, options.keyMappings.make);
  const model = getValue(record, options.keyMappings.model);

  const odometer = getValue(record, options.keyMappings.odometer);

  const uniqueId = `${serialNumber}-${make}-${model}`;

  return {
    key: uniqueId,
    data: {
      serialNumber,
      name,
      make,
      model,
      odometer,
      type: getValue(record, options.keyMappings.type),
    },
  };
};

export const getInventoryItem = (record, options = { keyMappings }) => {
  record = cleanKeys(record);

  const partNumber = getValue(record, options.keyMappings.partNumber);
  const description = getValue(record, options.keyMappings.description);
  const quantity = getValue(record, options.keyMappings.quantity);

  const uniqueId = `${partNumber}`;

  return {
    key: uniqueId,
    data: {
      partNumber,
      description,
      quantity,
    },
  };
};

export const getMaintenanceHistory = (record, options = { keyMappings }) => {
  record = cleanKeys(record);

  const originalCall = getValue(record, options.keyMappings.originalCall);
  const summary = getValue(record, options.keyMappings.summary);
  const recommendations = getValue(record, options.keyMappings.recommendations);
  const performedBy = getValue(record, options.keyMappings.performedBy);
  const appointmentDate = getValue(record, options.keyMappings.appointmentDate);
  const hours = getValue(record, options.keyMappings.hours);
  const adjustedHours = getValue(record, options.keyMappings.adjustedHours);

  const uniqueId = `${originalCall}-${appointmentDate}`;

  return {
    key: uniqueId,
    data: {
      originalCall,
      summary,
      recommendations,
      performedBy,
      appointmentDate,
      hours,
      adjustedHours,
    },
  };
};
