import React, { useCallback, useEffect, useRef, useState } from "react";
import Table from "../../shared/Table";
import Button from "../../shared/Button";
import ButtonDropdown from "../../shared/ButtonDropdown";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utils";
import useWindowSize from "../../hooks/useWindowSize";
import Modal from "../../shared/Modal";
import { renderColumnDefs } from "../../shared/Table/table.utils";
import { SidebarContainer } from "../../components";
import TableHeader from "../../shared/Table/TableHeader";

const TableLayout = ({
  data,
  columns,
  id,
  loading,
  pageSizeOptions,
  actions,
  createPath,
  handleEdit,
  handleMove,
  handleDelete,
  editPath,
  noEdit,
  noDelete,
  onUpdate,
  fullSelectedItem,
  title,
  enableSorting,
  hideActions,
  hideMainAction,
  handleDownloadPDF,
  ...rest
}) => {
  let table;
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const { breakpoint } = useWindowSize();
  const isMobile = breakpoint === "sm" || breakpoint === "xs";

  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  // const tableColumns = React.useMemo(() => columns, [columns]);
  // const tableData = React.useMemo(() => data, [data]);

  useEffect(() => {
    if (rest.clearSelectedItems) {
      tableRef?.current?.resetSelectedRows(false);
      setSelectedItems([]);
    }
  }, [rest.clearSelectedItems]);

  const handleRowSelection = useCallback((value) => {
    rest.sendSelectedItems && rest.sendSelectedItems(value);
    setSelectedItems(value);
  }, []);

  const buttonSize = "xs"; //rest.buttonSize || (isMobile && "sm");
  const renderTableActions = (e) => {
    const options = [
      {
        title: "Edit",
        onClick: () =>
          handleEdit
            ? handleEdit(
                fullSelectedItem ? selectedItems[0] : selectedItems[0]._id
              )
            : navigate(`/${editPath || id}/${selectedItems[0]._id}`),
      },
      {
        title: "Delete",
        onClick: () => clickDelete(selectedItems),
      },
      {
        title: "Move",
        onClick: () => handleMove(selectedItems),
      },
      ...(handleDownloadPDF
        ? [
            {
              title: "Download PDF",
              onClick: () => handleDownloadPDF(selectedItems),
              disabled: Object.keys(selectedItems).length === 0,
            },
          ]
        : []),
    ]
      .filter((item) => {
        if (noEdit && item.title === "Edit") {
          return false;
        }
        if (noDelete && item.title === "Delete") {
          return false;
        }
        if (!handleMove && item.title === "Move") {
          return false;
        }
        return true;
      })
      .map((item) => {
        if (Object.keys(selectedItems).length > 1 && item.title === "Edit") {
          return { title: item.title, disabled: true };
        }
        return item;
      });

    return (
      !hideActions &&
      (actions || actions === null ? (
        actions
      ) : (
        <div className="flex items-center">
          {(handleEdit || handleDelete) && (
            <ButtonDropdown
              size={buttonSize}
              className="mr-2"
              type="alternative"
              data={options}
              disabled={Object.keys(selectedItems).length === 0}
            >
              Actions
            </ButtonDropdown>
          )}
          <Button
            className="w-full"
            size={buttonSize}
            onClick={() =>
              rest.mainAction && rest.mainAction.onClick
                ? rest.mainAction.onClick()
                : navigate(createPath ? createPath : `/${id}/create`)
            }
          >
            {rest.mainAction && rest.mainAction.title
              ? rest.mainAction.title
              : "Create New"}
          </Button>
        </div>
      ))
    );
  };

  // UPDATE DATA
  function updateData(obj, setLoading) {
    const data = {
      ...obj,
    };
    onUpdate && onUpdate(data, setLoading);
  }

  // DELETE MODAL
  function clickDelete() {
    if (rest.handleDeleteClick) {
      rest.handleDeleteClick();
      return;
    }
    setDeleteModalVisible(true);
  }

  async function deleteSelectedItems() {
    setLoadingDelete("delete");
    const deleted = await handleDelete(selectedItems);
    if (deleted.status === "success") {
      closeDeleteModal();
      setSelectedItems([]);
    }
    setLoadingDelete("");
  }

  function closeDeleteModal() {
    setDeleteModalVisible(false);
    table.resetRowSelection(true);
  }

  const deleteModalFooter = (
    <div>
      <Button className="mr-3" onClick={closeDeleteModal} type="alternative">
        Cancel
      </Button>
      <Button
        loading={loadingDelete || rest.deleteLoading}
        disabled={loadingDelete || rest.deleteLoading}
        onClick={deleteSelectedItems}
        type="danger"
      >
        Delete
      </Button>
    </div>
  );
  const deleteModalContent = (
    <div className="font-base">
      Are you sure you want to delete these items?
    </div>
  );

  const tableColumns = React.useMemo(
    () => renderColumnDefs(data) || [],
    [data]
  );

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderHeader = (headerProps) => {
    table = headerProps.table;
    return (
      <div ref={headerRef}>
        <TableHeader {...headerProps} renderHeader={null} />
      </div>
    );
  };

  // const emptyState = {}

  return (
    <>
      <div className="border-b">
        <SidebarContainer
          title={title}
          // header={renderHeader}
          actions={actions}
          hideSidebar
          hideHeader
          // sidebarContent={sidebarContent}
          // sidebarTitle={<h2 className="text-xl bg-white py-2">Communications</h2>}
        >
          {({ parentHeight, parentWidth }) => {
            const offsetTop = headerContext?.offsetTop;
            const offsetHeight = headerContext?.offsetHeight;
            const wrapperStyle = {
              height: `${parentHeight - offsetTop - offsetHeight}px`,
              minHeight: `${parentHeight - offsetTop - offsetHeight}px`,
            };
            // const panelStyle = {
            //   height: `${parentHeight - offsetTop - offsetHeight}px`,
            // };

            return (
              <Table
                {...rest}
                ref={tableRef}
                rowClasses="h-[55px]"
                className="w-full"
                innerWrapclassName="border-b"
                tableInnerWrapStyle={wrapperStyle}
                tableClassName="min-w-full h-auto"
                search
                loading={loading}
                title={title || capitalizeFirstLetter(id)}
                columns={columns || tableColumns}
                data={data}
                renderActions={renderTableActions}
                pageSizeOptions={pageSizeOptions}
                onSelectedRowsChange={handleRowSelection}
                pagination={rest.pagination}
                updateData={updateData}
                defaultPageSize={20}
                enableSorting={
                  typeof enableSorting === "boolean" ? enableSorting : true
                }
                renderHeader={(e) => renderHeader(e)}
              />
            );
          }}
        </SidebarContainer>
      </div>
      <Modal
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
        title={`Delete ${Object.keys(selectedItems).length} item(s)`}
        footer={deleteModalFooter}
        content={deleteModalContent}
        size="md"
        footerPosition="right"
      />
    </>
  );

  // return (
  //
  //     <Modal
  //       visible={deleteModalVisible}
  //       setVisible={setDeleteModalVisible}
  //       onClose={() => setDeleteModalVisible(false)}
  //       title={`Delete ${Object.keys(selectedItems).length} item(s)`}
  //       footer={deleteModalFooter}
  //       content={deleteModalContent}
  //       size="md"
  //       footerPosition="right"
  //     />
  //   </div>
  // );
};

export default TableLayout;
